import { useState, memo, lazy, Suspense } from "react";
import classNames from "classnames";
import styles from "./chatMiniSidebar.module.scss";
import { NavLink } from "react-router-dom";
import clsx from "classnames";

import { PlanIcon, SettingIcon, FreeCreditsIcon, PlusIcon } from "../icons";
import { Separator } from "components";
import { ThemeSwitch } from "../ThemeSwitch";

import useRouter from "hooks/useRouter";
import { RoutePaths } from "pages/routePaths";

import { useSelector } from "redux/hooks";
import links from "core/links";
import { ChatRoute } from "pages/ChatPage";
import { ChatSideBarFooter } from "../ChatSideBarFooter";
import { SearchIcon } from "../icons/SearchIcon";
import { HistoryIcon } from "views/layout/Navbar/components/icons";
import { useToggleSidebar } from "hooks/services/ToggleSidebarProvider";
// import { WorkspaceIcon } from "../icons/WorkspaceIcon";
import { EmptyUploadedFiles, setIsModalOpen } from "redux/actions";

import { WorkspaceSearchField } from "pages/Workspaces/pages/WorkspaceConversation.tsx/InfoComponent/WorkspaceSearchField";
import AnimatedModal from "components/AnimateModal/AnimateModal";
import { UploadedFilePreview } from "pages/Workspaces/pages/WorkspaceConversation.tsx/Threads/Components/UploadedFilePreview";

const FreeCreditsModal = lazy(() =>
  import("pages/ChatPage/components/freeCreditsModal").then((module) => ({
    default: module.FreeCreditsModal,
  }))
);
interface IProps {
  onStartNewChat?: ({ toChat }: { toChat: boolean }) => void;
  isMainScreenOpen?: boolean;
  itemStyles?: any;
  isAnswerComplete?: boolean;
}

export const ChatMiniSidebar = memo(
  ({ onStartNewChat, isMainScreenOpen, itemStyles, isAnswerComplete }: IProps) => {
    const { includeRoute, pathname,
      // push
     } = useRouter();
    const { userDetail, theme } = useSelector((state) => state.authReducer);
    const { PrivateChat, newMessages } = useSelector((state) => state.chatReducer);
    const { toggleSidebar } = useToggleSidebar();
    const { showCreateWorkspace, isModalOpen } = useSelector((state) => state.workSpaceReducer);

    const [showReferralModal, setShowReferralModal] = useState<boolean>(false);

    const workSpace = pathname.includes("/thread");
    const pathParts = pathname.split("/").filter(Boolean);
    // Parse path to determine which component to render
    const isWorkspacePath = pathParts[0] === "workspaces";
    const hasThreadId = pathParts.includes("thread") && pathParts.length > pathParts.indexOf("thread") + 1;

    // Decide which content to render
    const renderThreadsContent = isWorkspacePath && hasThreadId;

    const isButtonActive = (): boolean => {
      let isActive = false;
      if (includeRoute(ChatRoute.History)) {
        isActive = true;
      } else if (includeRoute(ChatRoute.New) && !isMainScreenOpen) {
        isActive = true;
      } else if (includeRoute(RoutePaths.Workspaces)) {
        isActive = true;
      }
      return isActive;
    };

    return (
      <>
        <div className={styles.container}>
          <div className={styles.headSection}>
            <div className={clsx(showCreateWorkspace && styles.isbackdrop, styles.themeallow, "mt-3")} >
              <button
                className={classNames(styles.newChatBtn, {
                  [styles.light]: theme === "light",
                  [styles.dark]: theme === "dark",
                })}
                onClick={() => {
                  if(renderThreadsContent || (workSpace && newMessages.length > 0 )) {
                    if(isAnswerComplete) setIsModalOpen(true);
                  } else if (isButtonActive()) onStartNewChat?.({ toChat: true });
                }}
                data-testid="new-chat-btn"
              >
                <div className={styles.btnContent}>
                  <PlusIcon />
                </div>
              </button>
            </div>
            <Separator />
            <div
              className={`flex justify-center mt-4 cursor-pointer ${(PrivateChat || showCreateWorkspace) ? styles.isbackdrop : ""}`}
              onClick={toggleSidebar}
            >
              <SearchIcon chatsidebar={true} />
            </div>
            <Separator />
            {/* <div
              className={`flex justify-center mt-4 cursor-pointer ${(PrivateChat || showCreateWorkspace) ? styles.isbackdrop : ""}`}
              onClick={() => push(`/${RoutePaths.Workspaces}`)}
            > <WorkspaceIcon />
            </div>
            <Separator /> */}
            <div
              className={`flex justify-center mt-4 cursor-pointer ${(PrivateChat || showCreateWorkspace) ? styles.isbackdrop : ""}`}
              onClick={toggleSidebar}
            >
              <HistoryIcon />
            </div>
          </div>
          <div className={styles.footer}>
            <div className={styles.Hidefootersetting}>
              <ChatSideBarFooter
                itemStyles={itemStyles}
                toggleSidebar={toggleSidebar}
                userDetail={userDetail}
                theme={theme}
              />
            </div>
            <div className={styles.themeallow}>
              <div
                className={classNames(styles.itemContainer, {
                  [styles.isbackdrop]: PrivateChat || showCreateWorkspace,
                })}
                onClick={() => setShowReferralModal(true)}
                data-testid="sidebar-chat-newchat-earnCredit"
              >
                <FreeCreditsIcon />
              </div>
              <Separator height="18px" />
              {userDetail?.user.team.role === "admin" && (
                <>
                  <NavLink
                    className={classNames(styles.itemContainer, {
                      [styles.isbackdrop]: PrivateChat || showCreateWorkspace,
                    })}
                    to={`/${RoutePaths.Settings}/${RoutePaths.CurrentPlan}`}
                    data-testid="chat-sidebar-currentplan"
                  >
                    <PlanIcon />
                  </NavLink>
                  <Separator height="18px" />
                </>
              )}
              <a
                href={links.ChromeStorePluginUrl}
                target="_blank"
                rel="noreferrer"
                className={classNames(styles.itemContainer, {
                  [styles.isbackdrop]: PrivateChat || showCreateWorkspace,
                })}
                data-testid="chrome-store-plugin"
              >
                <img
                  src="/ChromeIcon.svg"
                  style={{ backgroundColor: "transparent" }}
                />
              </a>
              <Separator height="18px" />
              <NavLink
                className={classNames(styles.itemContainer, {
                  [styles.isbackdrop]: PrivateChat || showCreateWorkspace,
                })}
                to={`/${RoutePaths.Settings}`}
                data-testid="sidebar-setting"
              >
                <SettingIcon />
              </NavLink>
            </div>
            <div className=" pt-[18px]">
              <ThemeSwitch minSidebar={true} />
            </div>
          </div>
        </div>

        {isModalOpen && (
          <AnimatedModal isOpen={isModalOpen} onClose={() => {
            setIsModalOpen(false)
            EmptyUploadedFiles();
          }} threads={true}>
            <UploadedFilePreview /> 
            <WorkspaceSearchField  threads={true} chat={true} wsNewChat={true} 
            // setIsModalOpen={setIsModalOpen} 
            />
          </AnimatedModal>
        )}

        <Suspense fallback={<></>}>
          {showReferralModal && (
            <FreeCreditsModal onClose={() => setShowReferralModal(false)} />
          )}
        </Suspense>
      </>
    );
  }
);
