import { IUploadFile } from "pages/ChatPage";
import { IErrorMessage, IFileErrorState } from "pages/ChatPage/pages";
import { Dispatch, SetStateAction } from "react";
import { ChatType, IChatContentUploadFile, IChatModel, IFile, IUploadedFile, PreviewableFile, setChatModel, UploadFile } from "redux/actions";
import { EThemeType } from "redux/reducers";

interface IValidateFileExtension {
  file?: File;
  url?: string;
  types: string[];
}

export type EFileSizes = keyof typeof FileSizes;

export const FileSizes = {
  KB: 1024,
  MB: 1024 * 1024,
  GB: 1024 * 1024 * 1024,
  TB: 1024 * 1024 * 1024 * 1024,
} as const;

interface IValidateFileSize {
  file: File;
  sizeOptions: {
    size: number;
    unit: EFileSizes;
  };
}

interface ICalculateRenderSize {
  originalWidth: number;
  originalHeight: number;
  containerWidth: number;
  containerHeight: number;
}

interface Settings {
  real_time_results: boolean;
  related_questions: boolean;
  send_message_with_enter: boolean;
}

export const randomNumber = (): string => {
  const date = new Date();
  const randomNum = Math.floor(Math.random() * 1000);
  const randomNumberWithTime = `${date.getHours()}${date.getMinutes()}${date.getSeconds()}${randomNum}`;
  return randomNumberWithTime;
};

export const capitalizeFirstLetter = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const debounce = <T extends (...args: any[]) => any>(
  func: T,
  delay: number
) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: ThisParameterType<T>, ...args: Parameters<T>) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
};

export const validateFileType = ({
  file,
  url,
  types,
}: IValidateFileExtension): boolean => {
  let returnValue = false;
  if (file) {
    const ext = file?.name.split(".").pop()?.toLowerCase() as string;
    if (types.includes(ext)) returnValue = true;
  }

  if (url) {
    const ext = url.split(".").pop()?.toLowerCase() as string;
    if (types.includes(ext)) returnValue = true;
  }

  return returnValue;
};

export const validateFileSize = ({
  file,
  sizeOptions,
}: IValidateFileSize): boolean => {
  const { size, unit } = sizeOptions;
  if (file.size > size * FileSizes[unit]) {
    return false;
  }
  return true;
};

export const calculateRenderedSize = ({
  originalWidth,
  originalHeight,
  containerWidth,
  containerHeight,
}: ICalculateRenderSize) => {
  const aspectRatio = originalWidth / originalHeight;

  let renderedWidth = containerWidth;
  let renderedHeight = renderedWidth / aspectRatio;

  if (renderedHeight > containerHeight) {
    renderedHeight = containerHeight;
    renderedWidth = renderedHeight * aspectRatio;
  }

  return {
    width: renderedWidth,
    height: renderedHeight,
  };
};

export const getThemeClass = (
  baseStyle: string,
  theme: EThemeType | undefined,
  styles: { light: string; dark: string }
) => {
  return theme === "light"
    ? `${baseStyle} ${styles?.light}`
    : `${baseStyle} ${styles?.dark}`;
};

export const preprocessLaTeX = (content: string) => {
  // Escape dollar signs that are not LaTeX delimiters
  const escapedDollarSigns = content.replace(/(?<!\\)\$(?=\d|_\d|___|\s*\[\w+\]|\d{1,3}(?:,\d{3})*(?:\.\d+)?)/g, '\\$');

  // Replace block-level LaTeX delimiters \[ \] with $$ $$
  const replacedBlockLaTeX = escapedDollarSigns.replace(
    /\\\[\s*(.*?)\s*\\\]/g,
    (_, equation) => `$$${equation}$$`
  );

  // Replace inline LaTeX delimiters \( \) with $ $
  const replacedInlineLaTeX = replacedBlockLaTeX.replace(
    /\\\(\s*(.*?)\s*\\\)/g,
    (_, equation) => `$${equation}$`
  );

  // Ensure remaining $ $ are treated as inline LaTeX
  const finalProcessedContent = replacedInlineLaTeX.replace(
    /(?<!\\)\$(.*?)\$/g,
    (_, equation) => `$${equation}$`
  );

  return finalProcessedContent;
};

export const calculateCredit = (gptModel: IChatModel, settings: Settings,
  selectedFile: IUploadFile[] | undefined,
  url: string, isAnswerComplete: boolean | undefined) => {
  const creditsPerMessage = gptModel.credits_per_message;
  const realTimeResults = settings?.real_time_results;
  let credit = creditsPerMessage + (realTimeResults ? 1 : 0);
  const urlExtension = url?.split('.').pop()?.toLowerCase() || '';
  const imgURL = ["png", "jpg", "jpeg", "webp", "gif"]
  const isFirstFileImage = selectedFile && selectedFile[0]?.file.type.startsWith("image/");

  if (selectedFile && selectedFile.length > 0 && isFirstFileImage && isAnswerComplete === true) {
    credit += selectedFile.length;
  }
  else if (imgURL.includes(urlExtension) && isAnswerComplete === true) {
    credit += (url ? 1 : 0);
  }

  return credit;
};

export const ErrorMessages = (errorMessage: IErrorMessage[], setErrorModal: Dispatch<SetStateAction<IFileErrorState>>) => {
  const uniqueErrors = new Set<string>();

  // Filter out duplicate errors and combine the messages
  const combinedErrorMessages = errorMessage && errorMessage
    .filter(error => {
      if (uniqueErrors.has(error.error)) {
        return false; // Skip the duplicate error
      }
      uniqueErrors.add(error.error); // Add new error to the Set
      return true; // Keep the unique error
    })
    .map(error => ` ${error.error}`)
    .join('\n');

  if (errorMessage && errorMessage.length > 0) {
    setErrorModal({
      message: combinedErrorMessages ?? '', // Pass the combined error messages here
      show: true,
    });
  }
}

export const convertFileNamesToLowercase = (imageFiles: File[]): File[] => {
  return imageFiles.map(file => {
    const fileType = file.type.toLowerCase();
    const fileName = file.name;
    const lastDotIndex = fileName.lastIndexOf(".");
    const nameWithoutExtension = fileName.slice(0, lastDotIndex);
    const extension = fileName.slice(lastDotIndex + 1).toLowerCase();
    const newFileName = `${nameWithoutExtension}.${extension}`;

    return new File([file], newFileName, {
      type: fileType,
      lastModified: file.lastModified,
    });
  });
};

export const handleModelSwitch = (gptModel: IChatModel | undefined, chatModels: IChatModel[], isImageFile: boolean, isVideoFile: boolean, isAudioFile: boolean) => {
  const isImageChat = gptModel?.type.includes('image_chat');

  const videoSupportedModel = chatModels
    .filter(model => model.type.includes(ChatType.video))
    .map(model => model.name);

  const audioSupportedModel = chatModels
  .filter(model => model.type.includes(ChatType.audio))
  .map(model => model.name);

  const supportedModels = chatModels
  .filter((model) => model.type.includes(ChatType.image_chat))
  .map((model) => model.name);

  if (!isImageChat && gptModel?.name && isImageFile) {
    if (!supportedModels.includes(gptModel?.name)) {
      const imageChatModel = localStorage.getItem('imageChatGptModel');
      const selectedModal = imageChatModel ? JSON.parse(imageChatModel) : (chatModels[1]);
      if (imageChatModel) {
        localStorage.setItem('GptModel', imageChatModel);
      }
      else {
        localStorage.setItem('GptModel', JSON.stringify(chatModels[1]));
        localStorage.setItem('imageChatGptModel', JSON.stringify(chatModels[1]));
      }
      setChatModel(selectedModal)
    }
  }

  if (!gptModel?.type?.includes(ChatType.document) && !isVideoFile && !isAudioFile) {
    const model = localStorage.getItem(!isVideoFile ? 'documentChatGptModel' : "videoChatGptModel");
    let selectedModel = model ? JSON.parse(model) : chatModels[0];
    if (model) localStorage.setItem('GptModel', model);
    else {
      localStorage.setItem(!isVideoFile ? 'documentChatGptModel' : "videoChatGptModel", JSON.stringify(chatModels[0]));
      localStorage.setItem('GptModel', JSON.stringify(chatModels[0]));
    }
    setChatModel(selectedModel);
  }

  if (isVideoFile ||  isAudioFile) {
    if (!videoSupportedModel.includes(gptModel?.name ?? '') || !audioSupportedModel.includes(gptModel?.name ?? '')
    ) {
      const imageChatModel = localStorage.getItem(isVideoFile? 'videoChatGptModel': 'audioChatGptModel');
      const model = imageChatModel!=='undefined'?  JSON.parse(imageChatModel ?? ""): null;
      if (!videoSupportedModel.includes(model?.name ?? '') || !audioSupportedModel.includes(gptModel?.name ?? '')) {
        const selectedModel = chatModels[0];
        localStorage.setItem(isVideoFile? 'videoChatGptModel': 'audioChatGptModel', JSON.stringify(selectedModel));
        localStorage.setItem('GptModel', JSON.stringify(selectedModel));
        setChatModel(selectedModel);
      }
      else {
        const selectedModel = imageChatModel ? JSON.parse(imageChatModel) : chatModels[0];
        localStorage.setItem(isVideoFile? 'videoChatGptModel': 'audioChatGptModel', JSON.stringify(selectedModel));
        localStorage.setItem('GptModel', JSON.stringify(selectedModel));
        setChatModel(selectedModel);
      }
    }
  }
}

export const validateVideoDurations = async (videoFile: File): Promise<number> => {
  return await new Promise<number>((resolve, reject) => {
    const videoElement = document.createElement('video');
    videoElement.preload = 'metadata';

      videoElement.onloadedmetadata = () => {
        window.URL.revokeObjectURL(videoElement.src); // Clean up
        if (videoElement.duration === Infinity || isNaN(videoElement.duration)) {
          // Attempt to fix duration issues by loading more of the video
          
          videoElement.currentTime = Number.MAX_SAFE_INTEGER;
          videoElement.ontimeupdate = () => {
            videoElement.ontimeupdate = null; // Remove handler after fix
            videoElement.currentTime = 0; // Reset playback position
            resolve(videoElement.duration);
          };
        } else {
          resolve(videoElement.duration);
        }
      };

    videoElement.onerror = () => {
      reject(0);
    };

    videoElement.src = URL.createObjectURL(videoFile); // Load video
  });
};

// Helper function to validate video durations
export const getVideoDuration = (url: string): Promise<number> => {
  return new Promise((resolve, reject) => {
    const videoElement = document.createElement('video');
    videoElement.preload = 'metadata';
    videoElement.src = url;

    videoElement.onloadedmetadata = () => {

      if (videoElement.duration === Infinity || isNaN(videoElement.duration)) {
        // Attempt to resolve the duration issue
        videoElement.currentTime = Number.MAX_SAFE_INTEGER;
        videoElement.ontimeupdate = () => {
          videoElement.ontimeupdate = null; // Remove event listener
          videoElement.currentTime = 0; // Reset playback position
          resolve(videoElement.duration); // Get actual duration
        };
      } else {
        resolve(videoElement.duration); // Duration in seconds
      }
    };

    videoElement.onerror = () => {
      reject(new Error('Unable to load video metadata.'));
    };
  });
};

export const getImageSize = async (url: string): Promise<number> => {
  try {
    const response = await fetch(url, { method: 'HEAD' });
    const contentLength = response.headers.get('Content-Length');
    return contentLength ? parseInt(contentLength) : 0;
  } catch (error) {
    console.error('Error fetching image size:', error);
    return 0;
  }
};

export const getFileSize = async (url: string): Promise<number> => {
  try {
    // Send a HEAD request to get the metadata
    const response = await fetch(url, { method: 'HEAD' });

    const contentLength = response.headers.get('Content-Length');

    if (contentLength) {
      // Convert size from bytes to MB (or any other unit)
      const sizeInMB = parseInt(contentLength, 10) / (1024 * 1024);
      return sizeInMB; // Return the file size in MB
    } else {
      return 0; // Return 0 if size is not available
    }
  } catch (error) {
    console.error('Error fetching file size:', error);
    return 0; // Return 0 in case of an error
  }
}

export const validateAudioDuration = async (audioFile: File): Promise<number> => {
  return await new Promise<number>((resolve, reject) => {
    const audioElement = document.createElement('audio');
    audioElement.preload = 'metadata';

    audioElement.onloadedmetadata = () => {
      window.URL.revokeObjectURL(audioElement.src); // Clean up
      if (audioElement.duration === Infinity || isNaN(audioElement.duration)) {
        // Attempt to fix duration issues by loading more of the audio
        audioElement.currentTime = Number.MAX_SAFE_INTEGER;
        audioElement.ontimeupdate = () => {
          audioElement.ontimeupdate = null; // Remove handler after fix
          audioElement.currentTime = 0; // Reset playback position
          resolve(audioElement.duration);
        };
      } else {
        resolve(audioElement.duration);
      }
    };

    audioElement.onerror = () => {
      reject(new Error("Failed to load audio metadata."));
    };

    audioElement.src = URL.createObjectURL(audioFile); // Load audio
  });
};


// Type guard to check if an object is compatible with PreviewableFile
export function isPreviewableFile(obj: any): obj is PreviewableFile {
  return obj && 
    (obj.file instanceof File || (typeof obj.file === 'object' && obj.file && 'name' in obj.file)) &&
    (obj.S3Link === undefined || typeof obj.S3Link === 'string') &&
    (obj.fileSize === undefined || typeof obj.fileSize === 'number');
}

// Adapter function to convert UploadFile to PreviewableFile
export function adaptUploadFile(file: UploadFile | IChatContentUploadFile): PreviewableFile {
  return {
    file: file.file,
    S3Link: file.S3Link,
    fileSize: file.fileSize
  };
}

// Adapter function to convert IUploadedFile to PreviewableFile
export function adaptUploadedFile(file: IUploadedFile): PreviewableFile {
  // Create a File-like object from IWSFile
  if (!file.file) {
    throw new Error("File object is null or undefined");
  }
  const fileObj = {
    name: file.file.fileName,
    type: file.file.mime_type,
    size: file.file.size ? parseInt(file.file.size) : undefined
  };
  
  return {
    file: fileObj,
    // Assuming the S3Link can be constructed from the path
    S3Link: file.file.path,
    // Convert string size to number if available
    fileSize: file.file.size ? parseFloat(file.file.size) / (1024 * 1024) : undefined // Convert to MB
  };
}

export async function adaptIFileAsync(file: IFile): Promise<PreviewableFile> {
  let fileSize: number | undefined;

  if (file.path) {
    try {
      fileSize = await getFileSize(file.path); // Assuming this returns MB
    } catch (error) {
      console.error(`Failed to get file size for ${file.path}:`, error);
    }
  }

  return {
    file: {
      name: file.name,
      type: file.name.split('.').pop()?.toLowerCase(),
    },
    S3Link : file.path || '',
    fileSize
  };
}

export async function adaptIUploadAsync(file: IUploadFile): Promise<PreviewableFile> {
  let fileSize: number | undefined;

  if (file?.S3Link) {
    try {
      fileSize = await getFileSize(file.S3Link); // Assuming this returns MB
    } catch (error) {
      console.error(`Failed to get file size for ${file.S3Link}:`, error);
    }
  }

  return {
    file: {
      name: file.file.name,
      type: file.file.name.split('.').pop()?.toLowerCase(),
    },
    S3Link : file.S3Link || '',
    fileSize
  };
}

