import { useState, useEffect, useRef, useCallback, MutableRefObject } from "react";
import { useEffectOnce } from "react-use";
import ReactGA from "react-ga4";
import useRouter from "hooks/useRouter";
import { useAppNotification } from "hooks/services/AppNotification";
import { useBeforeUnload } from "hooks/useBeforeUnload";
import { useNetwork } from "hooks/services/NetworkProvider";
import { API_CALLS_IN_PROGRESS, SETTINGS_API_CALLED, ACTIVE_PLAN_API_CALLED  } from 'hooks/ThreadHistoryContext'; // Adjust the path as needed

import {
      getActivePlan,
    stopGeneratingResponse,
    IUser,
    IMessage,
    generateRelatedQuestions,
    IChat,
      getChatSettings,
    IChatModel,
    IImage,
    IFile,
      SettingsBase,
      setDropDownOptions,
      setRememberSetting,
      RoleBot,
    setMessagesPagination,
    clearhistoryMessages,
      filterLanguages,
    regenerateModelNme,
      setCreditLoad,
      ShowSingleThread,
      setIsGenRes,
      setMsgGeneration,
    clearControllerState,
    getThreadTopicInfo,
    EmptyContentFile,
    setSelectedSubOptions,
} from "redux/actions";
import { useSelector } from "redux/hooks";
import { audioURL, getDropdownOptions, videoURL } from "utils/constants";
import { useMessageHandler } from "hooks/services/ChatServices/useMessageHandler";
import useChat from "hooks/services/ChatServices/useChat";
import { audioMaxCountReached, documentMaxCountReached, videoMaxCountReached } from "utils/chat";
import { ChatFooterMethods } from "pages/ChatPage/components/chatFooter";
import { useThreadStream } from "./services/ThreadServices/threadStream";
import { RoutePaths } from "pages/routePaths";

export const ChatRoute = {
    New: "new",
    History: "history",
} as const;

export interface IGetMessageAgainstKey {
    messagesArray: IMessage[];
    key: any;
}
export interface IUploadFile {
    id: string;
    file: File;
    status: "validating" | "uploading" | "uploaded" | "error";
    fileType: "document" | "image" | "video" | "audio";
    S3Link?: string;
}
export interface SelectedSubOptions {
    [key: string]: string;
}
// Interface to track API call status with a robust tracking system
interface ApiCallTracker {
  isInitialRender: boolean;
}

export const useThreadsHistory = (
  apiCallTracker?: MutableRefObject<ApiCallTracker>) => {
    // custom hooks
    const {
      done,
      setDone,
      ThreadStartStream,
      controllerRef,
  } = useThreadStream();

  const {
      getReaminingCredits,
      getMessageAgainstKey,
      getLastMessage,
      selectedMessages,
      messageHeight,
      setMessageHeight,
      setSelectedMessages,
      setSelectedChatId,
  } = useChat();

  const { isOnline } = useNetwork();
  const { pathname, push } = useRouter();
  const chatId = pathname.split("/")[4];
  const { triggerNotification } = useAppNotification();
  const workspace_id = pathname.split("/")[2];
  const workSpace = pathname.includes("workspaces");

  const pathParts = pathname.split("/").filter(Boolean);
    // Parse path to determine which component to render
  const isWorkspacePath = pathParts[0] === "workspaces";
  const hasThreadId = pathParts.includes("thread") && pathParts.length > pathParts.indexOf("thread") + 1;
  
      // Decide which content to render
  const renderThreadsContent = isWorkspacePath && hasThreadId;

  // data from reducer
  const chatModel = useSelector((state) => state.authReducer.gptModel);
  const { newMessages, messages, chatSetting, selectedSubOptions } = useSelector(
      (state) => state.chatReducer
  );
  const { gptModel } = useSelector((state) => state.authReducer);
  const currentPlan = useSelector(
      (state) => state.planSubscriptionReducer.activePlan
  );

  const {controller} = useSelector((state) => state.workSpaceReducer);
  const { userDetail } = useSelector((state) => state.authReducer);

  // target DOM using ref
  const chatContainerRef = useRef<HTMLDivElement | null>(null);
  const chatFooterRef = useRef<ChatFooterMethods>(null);

  // state management using useState hook
    const [creditLimitModal, setCreditLimitModal] = useState<boolean>(false);
    const toggleCreditLimitModal = () =>
      setCreditLimitModal((prev) => {
        if (prev) {
          return false;
        } else {
          ReactGA.event({
            action: "Credits_LimitReached_NotificationShown",
            category: "CreditCompletedPopupShown",
            label: "CreditCompletedPopupShown",
          });
          return true;
        }
      });
    const onConfirmCreditModal = () =>
      push(`/${RoutePaths.Settings}/${RoutePaths.CurrentPlan}`);

  const [showScrollToBottom, setShowScrollToBottom] = useState<boolean>(false);
  const [getMessagesLoading, setGetMessagesLoading] = useState<boolean>(false);
  const [sendMessageLoading, setSendMessageLoading] = useState<boolean>(false);
  const [isAnswerComplete, setIsAnswerComplete] = useState<boolean>(true);
  const [isAllChunksReceived, setIsAllChunksReceived] = useState(false);
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);
  const [IGLoading, setIGLoading] = useState<boolean>(false);
  const [chatItem, setChatItem] = useState<IChat>();

    const [credit, setCredit] = useState<number>(0);
    const [openHistory, setOpenHistory] = useState<boolean>(false);
    const [uploadingFiles, setUploadingFiles] = useState<IUploadFile[]>([]);
  const [fileS3Link, setFileS3Link] = useState<string[]>([]);
    const [messageId, setMessageId] = useState<string>("");

  // Abort Generate Related Question
  const abortControllerRef = useRef<AbortController | null>(null);

  // STATE FOR SHARING CHAT
  const [share, setShare] = useState<boolean>(false);
  const toggleShareChat = () => setShare((prev) => !prev);

    const [loadingSetting, setLoadingSetting] = useState<boolean>(false);
    const [settings, setSettings] = useState({
      real_time_results: chatSetting.real_time_results,
      related_questions: chatSetting.related_questions,
      send_message_with_enter: chatSetting.send_message_with_enter,
    });
      const [chatSettings, setChatSetting] = useState<boolean>(false);
  const {
      onNewTextChat,
      onRegularTextChat,
  } = useMessageHandler({
      chatId,
      newMessages,
      messages,
      setSendMessageLoading,
      setIsGenerating,
      setOpenHistory,
      setIGLoading,
      scrollToBottom: (options: { behavior: 'auto' | 'smooth' }) => {
          chatContainerRef.current?.scrollTo({ top: chatContainerRef.current?.scrollHeight, ...options });
      },
      setUploadingFiles,
      setFileS3Link,
      setIsFileUploading,
      setIsAnswerComplete,
      setIsAllChunksReceived,
      setDone,
      ThreadStartStream,
      abortControllerRef,
      workspace_id,
  });
    const handleContainerScroll = () => {
      if (chatContainerRef.current) {
        const { scrollTop, clientHeight, scrollHeight } =
          chatContainerRef.current;

        setShowScrollToBottom(
          Math.round(scrollTop + clientHeight) < Math.round(scrollHeight)
        );
      }
    };

    useEffect(() => {
      if (chatContainerRef.current) {
        chatContainerRef.current.addEventListener(
          "scroll",
          handleContainerScroll
        );
      }

      return () => {
        if (chatContainerRef.current) {
          chatContainerRef.current.removeEventListener(
            "scroll",
            handleContainerScroll
          );
        }
      };
    }, []);

    useEffect(() => handleContainerScroll(), [messages, newMessages]);

  const scrollToBottom = ({ behavior }: ScrollOptions) => {
      if (chatContainerRef.current) {
          const { scrollHeight } = chatContainerRef.current;
          chatContainerRef.current.scrollTo({
              top: scrollHeight,
              behavior: behavior,
          });
      }
  };

  const clearMessage = () => {
      if (chatFooterRef.current) chatFooterRef.current.resetMessage();
  };

  const onChangeChat = (online?: boolean) => {
      if (online) {
      } else clearMessage();
     
      setSendMessageLoading(false);
      setIsAnswerComplete(true);
      setIsAllChunksReceived(false);
      setMsgGeneration(false);

      EmptyContentFile();

      if (isGenerating) {
          setDone(true);
        //   setIsGenRes(true);
          onStopGeneratingResponse();
      }

      setIsGenerating(false);

      if (chatFooterRef.current) {
          chatFooterRef.current.onTextareaFocus()
      };
  };

const GetThreadChatHistory = (Thread_id: number, WS_Id: number ) => {
  // Create a unique lock key for this API call
  const lockKey = `thread-${Thread_id}-${WS_Id}`;
    
  // Check if this call is already in progress (synchronous check)
  if (API_CALLS_IN_PROGRESS.has(lockKey)) {
    return;
  }
  
  // Add to in-progress set (synchronous operation)
  API_CALLS_IN_PROGRESS.add(lockKey);
  
  // If this is the initial render and strict mode, let's skip API calls
  if (apiCallTracker?.current.isInitialRender) {
    apiCallTracker.current.isInitialRender = false;
    
    // Add a small delay to allow React to finish its current render cycle
    setTimeout(() => {
      executeApiCall();
    }, 0);
  } else {
    executeApiCall();
  }
  
  function executeApiCall() {
  
    if (abortControllerRef.current) {
        abortControllerRef.current.abort();
    }

    abortControllerRef.current = new AbortController();

    clearhistoryMessages();
    setMessagesPagination(1, 1);
    const page = 1;
    const perPage=10;
    getThreadTopicInfo(true);
    ShowSingleThread(
        Thread_id,
        WS_Id,
        page, perPage, 
        ({ signal: abortControllerRef.current.signal })
    )
        .then(() => {
            requestAnimationFrame(() => {
                setTimeout(() => {
                    scrollToBottom({ behavior: "auto" })
                }, 3);
                setGetMessagesLoading(false);

            });
            getThreadTopicInfo(false);

          // Remove from in-progress set only after completion
          API_CALLS_IN_PROGRESS.delete(lockKey);

        })
        .catch((err: any) => {
          getThreadTopicInfo(false);
          // Remove from in-progress set on error as well
          API_CALLS_IN_PROGRESS.delete(lockKey);
            setGetMessagesLoading(false);
            if (err.name !== 'AbortError') {
                triggerNotification({ message: err?.data?.message, type: "error" });
                if (err?.data?.message === "Chat not found")
                    push(`/${RoutePaths.Chat}/${ChatRoute.New}`);
            }
        });
      }
}

  const onGetChatHistory = useCallback(() => {

      if (chatId && workSpace ) {
          setTimeout(() => {
              setGetMessagesLoading(true);
          }, 0)
          
      GetThreadChatHistory(JSON.parse(chatId), JSON.parse(workspace_id));
      }
  }, [chatId]);

  const onBeforeUnload = () => {
      setDone(true);
      onStopGeneratingResponse();
  };

  useEffect(() => {
      if (!isOnline) {
          const online = true
          onChangeChat(online);
      }
  }, [isOnline]);

  useBeforeUnload(() => {
      if (isGenerating) {
          onBeforeUnload();
          return true;
      }
      return false;
  });

  useEffect(() => onGetChatHistory(), [onGetChatHistory]);

    useEffect(() => {
      const handleUpdate = (e: any) => {
        const settings = e.detail;
        if (settings?.key === "bot_settings") {
          setSelectedSubOptions(settings);
        }
        if (settings?.key === "chat_settings") {
          setSettings(settings);
          setRememberSetting(settings?.customized_response)
        }
  
      };
      document.addEventListener("updatedSettings", handleUpdate);
      return () => {
        document.removeEventListener("updatedSettings", handleUpdate);
      };
    }, []);

    useEffectOnce(() => {
      localStorage.removeItem('invite');
      if (userDetail?.token) {

    // Check if settings API has already been called
    if (!SETTINGS_API_CALLED.value) {
      SETTINGS_API_CALLED.value = true;

        setLoadingSetting(true);

        getChatSettings()
          .then((res) => {
            setLoadingSetting(false);
            const chatSettings = res as SettingsBase;

            const options = getDropdownOptions(
              chatSettings?.bot_settings_allowed
            );
            setDropDownOptions(options);
            filterLanguages(chatModel?.model ?? '');
            setRememberSetting(
              chatSettings?.chat_settings?.customized_response || false
            );
            setSettings({
              real_time_results:
                chatSettings?.chat_settings?.real_time_results || false,
              related_questions:
                chatSettings?.chat_settings?.related_questions || false,
              send_message_with_enter:
                chatSettings?.chat_settings?.send_message_with_enter || false,
            });
            if (chatSettings?.chat_settings?.customized_response === true) {
              setSelectedSubOptions({
                outputFormats:
                  chatSettings?.bot_settings?.output_format || "default",
                tones: chatSettings?.bot_settings?.tone || "default",
                writingStyles:
                  chatSettings?.bot_settings?.style_of_writing || "default",
                responseLengths: chatSettings?.bot_settings?.length || "default",
                language: chatSettings?.bot_settings?.language || "default",
              });
            }
            if (chatSettings?.chat_settings?.customized_response === false) {
              const updatedBot = {
                key: "bot_settings",
              };
              setSelectedSubOptions({
                outputFormats: 'default',
                tones: 'default',
                writingStyles: 'default',
                responseLengths: 'default',
                language: 'default',
              });
              RoleBot(updatedBot);
            }
          })
          .catch((err) => {
            setLoadingSetting(false);
            triggerNotification({ message: err?.data?.message, type: "error" });
          });
      }

    // Check if active plan API has already been called
    if (!ACTIVE_PLAN_API_CALLED.value) {
      ACTIVE_PLAN_API_CALLED.value = true;
        getActivePlan().then(() => {
          setCreditLoad(false);
        }).catch((err) => {
          setCreditLoad(false);
          triggerNotification({ message: err?.data?.message, type: "error" });
        });
      }
    }
    });

    useEffect(() => {
      if(chatSetting) {
        setSettings(chatSetting);
      }
    }, [chatSetting])

  useEffect(() => {
      localStorage.removeItem('invite');
      if (typeof done === "boolean" && done === false) {
          setIsGenerating(true);
      }
      if (done) {
          setDone(null);
          setIsAllChunksReceived(true);
          setSendMessageLoading(false);
          setIsGenerating(false);
      }
  }, [done]);

  const resetState = () => {
      setUploadingFiles!([]);
  }

  const onSendMessage = (
      question: string,
      chatModels: IChatModel = {} as IChatModel,
      regenerate?: boolean | undefined,
      images?: string[],
      filePath?: string[],
      ws_id?: string,
  ) => {

      if (isFileUploading) {
          return;
      }

      let fileExtension = "";

      if (filePath && filePath.length > 0) {
          fileExtension = filePath[0]?.split('.').pop()?.toLowerCase() || "";

          if (audioURL.includes(fileExtension)) {
              if (audioMaxCountReached(currentPlan, userDetail)) {
                    setMessageId!("audioChat.plan.max_count");
                  resetState();
                  return;
              }
          }
          if (videoURL.includes(fileExtension)) {
              if (videoMaxCountReached(currentPlan, userDetail)) {
                    setMessageId!("videoChat.plan.max_count");
                  resetState();
                  return;
              }
          }
          else {
              if (documentMaxCountReached(currentPlan, userDetail)) {
                    setMessageId!("documentChat.plan.max_count");
                  resetState();
                  return;
              }
          }
      }
      setShare(false);
      setMessageHeight(true);
      setChatItem(undefined);

      if (gptModel?.type.includes("text")) {
        const credits =
          Number(gptModel?.credits_per_message) +
          (images ? images?.length : 0) +
          (settings.real_time_results ? 1 : 0);
        setCredit(credits);
      }

      if (
          (question?.trim() ||
              (images && images?.length > 0) ||
              (filePath && filePath.length > 0)) &&
          !sendMessageLoading &&
          isAnswerComplete &&
          !IGLoading
      ) {
        
          if (
              Number(chatModel?.credits_per_message) >
              (userDetail?.user as IUser).activeSubscription.credits ||
              getReaminingCredits() <= 0
          ) {
              toggleCreditLimitModal();
          } else {
              ReactGA.event({
                  action: "Chat_Message_SendButtonPressed",
                  category: (userDetail?.user as IUser).activeSubscription.name,
                  label: new Date().toISOString(),
                  value: 4,
              });
              ReactGA.event({
                  action: `Chat_Model_${chatModel?.name
                      .replace(/-/g, "_")
                      .replace(/\./g, "")}_MessageSent `,
                  category: "MessageSentModel",
                  label: chatModel?.name?.replace(/-/g, "_").replace(/\./g, ""),
              });
                  abortControllerRef?.current?.abort();
                  setSendMessageLoading(true);
                  setIsAnswerComplete(false);
                  setIsAllChunksReceived(false);
                  setMsgGeneration(true);

                  if (chatId && !ws_id)
                      onRegularTextChat(
                          question,
                          chatModels,
                          regenerate,
                          images,
                          filePath
                      );
                  else{
                      onNewTextChat(question, chatModels, regenerate, images, filePath, ws_id);
                  }
              
              clearMessage();
              if (chatFooterRef.current) {
                  chatFooterRef.current.onTextareaFocus()
              };
          }
      }
  };

    const onCustomSubmit = ({
      event,
      message,
      ws_id,
    }: {
      event: React.FormEvent<EventTarget | HTMLFormElement>;
      message: string;
      ws_id?: string;
    }) => {
      event.preventDefault();
      onSendMessage(message, chatModel, false, [], [], ws_id);
    };

    const onEnter = ({
      event,
      message,
    }: {
      event: React.KeyboardEvent<HTMLTextAreaElement>;
      message: string;
    }) => {
      if (event.keyCode === 13 && event.shiftKey === false) {
          onCustomSubmit({ event: event, message });
      }
    };

    // Create a cancel function that can be used from components
const cancelThreadStream = () => {
        if (controllerRef.current) {
            controllerRef.current.abort();
          }
    // First abort the Redux controller
    if (controller) {
      controller.abort();
      clearControllerState();
    }
    
    // Return true to indicate successful cancellation
    return true;
  };

  const onStopGeneratingResponse = () => {
      if (chatFooterRef.current) {
          chatFooterRef.current.onTextareaFocus()
      };
      const messageWithChatId = getMessageAgainstKey({
          messagesArray: renderThreadsContent? messages : newMessages,
          key: "chat_id",
      });
      abortControllerRef.current = new AbortController();

      const _chatId = chatId ? Number(chatId) : messageWithChatId?.chat_id;

      const _messageId = getLastMessage()?.id;
 if (!_chatId || !_messageId ||  !isGenerating  ) return;

        cancelThreadStream();
      
      setMsgGeneration(false);
      setIsAnswerComplete(true);
      setIsGenerating(false);
      setDone(true);
      setIsGenRes(false);
      setIsAllChunksReceived(false);
      setIsFileUploading(false);
      setOpenHistory(false);
      regenerateModelNme('', false)
      stopGeneratingResponse({
          chatId: messageWithChatId?.chat_id,
          messageId: getLastMessage()?.id,
      })
          .then(() => {
              setIsAnswerComplete(true);
              setIsAllChunksReceived(true);
              setIsFileUploading(false);

              if (
                  chatId &&
                  chatSetting?.related_questions &&
                  !abortControllerRef?.current?.signal?.aborted
              ) {
                  generateRelatedQuestions({
                      chatId: messageWithChatId?.chat_id?? null,
                      messageViewType: "history",
                      signal: abortControllerRef?.current?.signal,
                  }).catch((err) => {
                      triggerNotification({ message: err?.data?.message, type: "error" });
                  });
              }
              if (
                  messageWithChatId?.chat_id &&
                  chatSetting?.related_questions &&
                  !abortControllerRef?.current?.signal?.aborted
              ) {
                  generateRelatedQuestions({
                      chatId: messageWithChatId?.chat_id,
                      messageViewType: "new",
                      signal: abortControllerRef?.current?.signal,
                  }).catch((err) => {
                      triggerNotification({ message: err?.data?.message, type: "error" });
                  });
              }
          })
          .catch((err) => {
              console.log(err);
          });
  };

  const onGetUserMessage = (messageIndex: number) => {
      let lastQuestion = "";
      let messageImages: string[] = [];
      let docChat: string[] = [];
      if (chatId) {
          lastQuestion =
              messages[messageIndex]?.type === "user"
                  ? (messages[messageIndex]?.content as string)
                  : "";
          messageImages =
              messages[messageIndex]?.type === "user"
                  ? (messages[messageIndex]?.images || []).map(
                      (image: IImage) => image.path
                  )
                  : [];
          docChat =
              messages[messageIndex]?.type === "user"
                  ? (messages[messageIndex]?.files || [])
                      .map((file: IFile) => file.path)
                      .filter((path): path is string => path !== null)
                  : [];
      }
      if (!chatId) {
          lastQuestion =
              newMessages[messageIndex]?.type === "user"
                  ? (newMessages[messageIndex]?.content as string)
                  : "";
          messageImages =
              newMessages[messageIndex]?.type === "user"
                  ? (newMessages[messageIndex]?.images || []).map(
                      (image: IImage) => image.path
                  )
                  : [];
          docChat =
              newMessages[messageIndex]?.type === "user"
                  ? (newMessages[messageIndex]?.files || [])
                      .map((file: IFile) => file.path)
                      .filter((path): path is string => path !== null)
                  : [];
      }

      return {
          lastQuestion: lastQuestion,
          images: messageImages,
          filePath: docChat,
      };
  };

  const onRegenerate = (
      messageIndex: number,
      model: IChatModel = {} as IChatModel,
      regenerateModel?: boolean | undefined,
  ) => {
      setShare(false);
      const regenerate = regenerateModel ?? true;
      let userMessage = onGetUserMessage(messageIndex);
      if (
          userMessage.lastQuestion ||
          userMessage.images.length > 0 ||
          userMessage.filePath.length > 0
      ) {
          onSendMessage(
              userMessage.lastQuestion,
              model,
              regenerate,
              userMessage.images,
              userMessage.filePath
          );
      }
  };
 const msg= newMessages? newMessages : messages;
 
  return {
    isAnswerComplete,
    isAllChunksReceived,
    isGenerating,
    isFileUploading,
    IGLoading,
    openHistory,
    uploadingFiles,
    fileS3Link,
    share,
    toggleShareChat,
   messages: msg,
    messageHeight,
    setMessageHeight,
    selectedMessages,
    setSelectedMessages,
    setSelectedChatId,
    onSendMessage,
    onCustomSubmit,
    onEnter,
    onRegenerate,
    setIsAnswerComplete,
    setIsGenerating,
    setUploadingFiles,
    chatItem,
    loadingSetting,
    settings,
    selectedSubOptions,
    setSelectedSubOptions,
    credit, setCredit,
    creditLimitModal,
    toggleCreditLimitModal,
    onConfirmCreditModal,
   sizeMessage: messageId,
   SetSizeMessage: setMessageId,
    chatFooterRef,
    getMessagesLoading,
    showScrollToBottom,
    scrollToBottom,
    chatContainerRef,
    GetThreadChatHistory,
    sendMessageLoading,
    onStopGeneratingResponse,
    chatSettings,
    setChatSetting,
    setSettings,

  };
};

export default useThreadsHistory;