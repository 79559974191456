import { useState, useEffect, useRef } from "react";
import {
    setInProgressWS,
    RemoveWSFiles,
    NewChatThreadWS,
    EmptyThreadChat,
    startNewChat,
    setEmptyThreadsHistory,
    setWsUploadedFile,
} from "redux/actions";
import useRouter from "hooks/useRouter";
import { CrossIcon } from "pages/Workspaces/components/icons/RightSectionIcon";
import { RoutePaths } from "pages/routePaths";
import { ChatRoute } from "pages/ChatPage";
import { useSelector } from "redux/hooks"; 
import classNames from "classnames";
import styles from "../workSpaceConversation.module.scss";
import { useWindowSize } from "hooks/useWindowSize";

import { RippleIconButton } from "components/RippleEffect/RippleEffects";

interface IProp {
    wsNewChat?: boolean | undefined;
}

export const ContextDropdown = ({wsNewChat} : IProp) => {
    const { theme } = useSelector((state) => state.authReducer);
    const { workSpaces, currentConversation, newChatThread } = useSelector((state) => state.workSpaceReducer);
    const { push } = useRouter();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const { PrivateChat } = useSelector(
        (state) => state.chatReducer
    );
    const { width } = useWindowSize();

    // Setup initialization effect
    useEffect(() => {
        if(wsNewChat) {
            const EmptyObj = {
                id: 0,
                created_at: '',
                description: '',
                instructions: '',
                name: '',
                settings: '',
                team_id: 0,
                updated_at: '',
                emoji: '',
            }
            EmptyThreadChat(EmptyObj);
        }
    }, [wsNewChat]);

    // Click outside handler
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    
    const toggleDropdown = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setIsOpen(prev => !prev);
    };
    
    const handleResetStates = () => {
        RemoveWSFiles();
        setInProgressWS("", "", "", "");
    };
    
    const handleConversation = (id: number) => {
        if(wsNewChat) {
            NewChatThreadWS(id);
        } else {
            setWsUploadedFile();
            setEmptyThreadsHistory();
            handleResetStates();
            startNewChat();
            push(`/${RoutePaths.WorkspaceHistory}/${id}`);
        }
        setIsOpen(false);
    };
    
    const handleSwitchChange = (checked: boolean) => {
        sessionStorage.setItem('privateChatActive', JSON.stringify(checked));
        localStorage.setItem('previousPrivateChat', JSON.stringify(checked));

        const chatUrl = `/${RoutePaths.Chat}/${ChatRoute.New}`;
        const newTab = window.open(chatUrl, '_blank');
        
        // If the new tab was successfully opened, we need to set its private chat state
        if (newTab) {
            localStorage.setItem('openInPrivateMode', 'true');
        }

        setIsOpen(false);
    };

    const rippleColor = theme === "dark" ? 'rgba(255, 255, 255, 0.5)' : 'rgba(0, 0, 0, 0.3)';

    // Create dropdown trigger element
    const triggerElement = (
        <RippleIconButton 
        color={'rgba(0, 0, 0, 0.3)'}
            className="font_inter flex items-center justify-center bg-[#EEF2FF] rounded-full px-2 py-1 cursor-pointer"
            style={{ border: "0.8px solid #4338CA" }}
            onClick={toggleDropdown}
        >
            <span className="text-sm mr-1.5">
                {newChatThread && wsNewChat 
                    ? newChatThread?.emoji !== "" ? newChatThread?.emoji : currentConversation?.emoji 
                    : currentConversation?.emoji}
            </span>
            <span className="text-[10px] font-normal text-[#3730A3] font-inter max-w-[95px] overflow-hidden text-ellipsis whitespace-nowrap inline-block">
                {newChatThread && wsNewChat 
                    ? newChatThread?.name !== "" ? newChatThread?.name : currentConversation?.name 
                    : currentConversation?.name}
            </span>
            <span className="ml-[10px] mt-[2px]">
                <CrossIcon conversation={true} />
            </span>
        </RippleIconButton>
    );

    return (
        <>
            {currentConversation?.name && (
                <div ref={dropdownRef} className="cursor-pointer relative">
                    {/* Custom dropdown trigger */}
                    {triggerElement}
                    
                    {/* Custom dropdown menu */}
                    {isOpen && (
                        <div 
                            className={`absolute ${width <= 360 ? "left-0" : "left-[0px]"} top-full mt-1 z-10 w-[200px] rounded-[6px] shadow-[0px_2px_12px_0px_rgba(0,0,0,0.25)] ${theme === "dark" ? "bg-[#252526] text-dark-light" : "bg-white text-light-default"}`}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <div className={classNames("font_inter", styles.DropDownbody, {
                                [styles.light]: theme === "light",
                                [styles.dark]: theme === "dark",
                            })}>
                                <div className={`flex flex-col gap-[10px] ${styles.WorkSpaceList}`}>
                                    {workSpaces.map((workspace) => (
                                        <RippleIconButton 
                                        dropdown={true}
                                        color={rippleColor}
                                            key={workspace.id} 
                                            className="flex items-center cursor-pointer  "
                                            onClick={() => {
                                                setTimeout(() => {
                                                    handleConversation(workspace.id);
                                                }, 5);
                                            }}
                                        >
                                            <span className="text-sm mr-2 relative z-[2]">{workspace.emoji}</span>
                                            <span className="text-[12px] font-normal text-[#171717] dark:text-[#fff] font-inter max-w-[135px] overflow-hidden text-ellipsis whitespace-nowrap inline-block">
                                                {workspace.name}
                                            </span>
                                        </RippleIconButton>
                                    ))}
                                </div>
                                <div className="h-[0.6px] bg-[#94A3B8]"></div>
                                <div
                                    className="flex flex-col py-[10px] px-[6px] gap-[14px]" 
                                    onClick={() => handleSwitchChange(!PrivateChat)}
                                >
                                    <div className="flex items-center cursor-pointer">
                                        <span className="text-sm mr-2 relative z-[2]">
                                            <img 
                                                src={require("pages/Workspaces/components/icons/incognito.png")} 
                                                alt="Incognito Icon" 
                                                className="w-5 h-5" 
                                            />
                                        </span>
                                        <span className="text-[12px] font-normal text-[#171717] dark:text-[#fff] font-inter">
                                            Use Incognito Mode
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </>
    );
};