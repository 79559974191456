import { FormattedMessage } from "react-intl";
import styles from "./uploadedFiles.module.scss";
import { CrossIcon } from "../icons/CrossIcon";
import { ErrorRotateIcon, TrashIcon } from "../icons/RightSectionIcon";
import { useSelector } from "redux/hooks";
import { DeleteS3Link, DeleteUploadedFile, removeFile, setDelUploadedFile, UploadFile } from "redux/actions";
import {
  PDFIcon,
  TextIcon,
  PPTXIcon,
  PPTIcon,
  CSVIcon,
  XLSXIcon,
  XLSIcon,
  SRTIcon,
  EMLIcon,
  DocIcon,
} from "pages/ChatPage/components/icons/DocIcon";
import classNames from "classnames";
import { getRelativeTime, textExtensions, audioURL, videoURL } from "utils/constants";
import { DefaultIcon } from "../icons/LinkIcon";
import { useMemo, useRef, useEffect, useState } from "react";
import FilePreviewModal from "../FilePreviewModal/FilePreviewModal";
import { adaptUploadFile, debounce } from "utils/functions";
import { getProcessedFileName, isImageFile, processS3Filename } from "utils/fileProcessing";
import { AudioPreview } from "pages/ChatPage/components/searchField/AudioPreview";
import VideoPreview from "pages/ChatPage/components/searchField/VideoPreview";
import { PhotoProvider, PhotoView } from "react-photo-view";
import 'react-photo-view/dist/react-photo-view.css';
import React from "react";
import { useFileUpload } from "utils/uploadUtils";
interface Iprops {
  hideContainer?: boolean;
  hideSubhead?: boolean;
}

const FileIconMap = {
  docx: <DocIcon />,
  pdf: <PDFIcon />,
  txt: <TextIcon />,
  "text/plain": <TextIcon />,
  pptx: <PPTXIcon />,
  presentation: <PPTXIcon />,
  ppt: <PPTIcon />,
  "ms-powerpoint": <PPTIcon />,
  csv: <CSVIcon />,
  xlsx: <XLSXIcon />,
  sheet: <XLSXIcon />,
  xls: <XLSIcon />,
  "ms-excel": <XLSIcon />,
  srt: <SRTIcon />,
  "application/x-subrip": <SRTIcon />,
  eml: <EMLIcon />,
  "message/rfc822": <EMLIcon />,
};

const cleanupFileName = (fileName: string): string => {
  // First handle the website- prefix if it exists
  let cleanedName = fileName.startsWith("website-")
    ? fileName.split("-").slice(2).join("-")
    : fileName;
  return cleanedName.includes("%") ? decodeURIComponent(cleanedName) : cleanedName;
};

export const UploadedFiles = ({ hideContainer, hideSubhead }: Iprops) => {

  const [previewFile, setPreviewFile] = useState<UploadFile | null>(null);
  const { workSpaceFiles } = useSelector((state) => state.workSpaceReducer);
  const { userDetail, theme } = useSelector((state) => state.authReducer);
  const planName = userDetail?.user?.activeSubscription?.name?.toLowerCase() || "";
  const isFreePlan = planName === "free";

  const [audioPreview, setAudioPreview] = useState<{ path: string; name: string } | null>(null);

  // Video preview states
  const [isVideoLoading, setIsVideoLoading] = useState<boolean>(true);
  const [showVideoPreview, setShowVideoPreview] = useState<boolean>(false);
  const [selectedVideo, setSelectedVideo] = useState<{ path: string; name: string } | null>(null);

  const isMounted = useRef(true);

  const { retryUpload } = useFileUpload();

// Update the handleRetry function
const handleRetry = (e: React.MouseEvent, file: { id: string, S3Link: string }): void => {
  e.stopPropagation();
  e.preventDefault();
  
  if (file.S3Link) {
    // Use retryUpload instead of onSubmit to retry with the same ID
    retryUpload(file.id, file.S3Link);
  }
};

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Setup z-index for PhotoView-Portal
  useEffect(() => {
    let observer: MutationObserver | null = null;

    const adjustPhotoViewPortalZIndex = () => {
      const portals = document.getElementsByClassName('PhotoView-Portal');
      Array.from(portals).forEach(portal => {
        if (portal instanceof HTMLElement && portal.style.zIndex !== '9000000') {
          portal.style.zIndex = '9000000';
          portal.style.position = 'absolute';
        }
      });
    };

    const debouncedAdjustPhotoViewPortalZIndex = debounce(adjustPhotoViewPortalZIndex, 100);

    observer = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList' &&
          Array.from(mutation.addedNodes).some(node =>
            node instanceof HTMLElement &&
            (node.classList.contains('PhotoView-Portal') ||
              node.querySelector('.PhotoView-Portal')))) {
          debouncedAdjustPhotoViewPortalZIndex();
          break; // Only need to adjust once per batch of mutations
        }
      }
    });

    adjustPhotoViewPortalZIndex();
    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      if (observer) {
        observer.disconnect();
        observer = null;
      }
    };
  }, []);

  // Optimized renderFileIcon function that uses the pre-defined map
  const renderFileIcon = useMemo(() => {
    const renderIcon = (fileType: string, fileName: string, isProcessing?: boolean, isError?: boolean) => {
      // Check for specific file extension first
      if (fileName.endsWith(".docx")) return FileIconMap.docx;

      // Check if any of the keys in FileIconMap are included in the fileType
      for (const [key, icon] of Object.entries(FileIconMap)) {
        if (fileType.includes(key)) {
          return icon;
        }
      }

      const fileExt = fileName.split(".").pop()?.toLowerCase(); // Extract file extension

      if (fileExt && textExtensions.includes(fileExt)) {
        return (
          <div
            className={classNames(styles.loader, {
              [styles.light]: theme === "light",
              [styles.dark]: theme === "dark",
            })}
          >
            <span className="text-[8px] font-medium text-[#A09FA2] uppercase">
              {fileExt}
            </span>
          </div>
        );
      }

      if (fileType === "" && fileName.endsWith(".srt")) return FileIconMap.srt;
      return <DefaultIcon isProcessing={isProcessing} isError={isError} />;
    };
    return renderIcon;
  }, []);

  const handleRemoveWS = (id: string) => {
    if (isMounted.current) removeFile(id);
  };

  const handleDeleteFile = (
    id: string,
    S3Link: string,
    uploaded_id: number | null
  ) => {
    if (isMounted.current) {
    DeleteS3Link(S3Link);
    if (uploaded_id !== null) {
      setDelUploadedFile(uploaded_id);
      DeleteUploadedFile(uploaded_id);
    }
    removeFile(id);
  }
}

  const extractFileExtension = (fileName: string) => {
    const parts = fileName.split('.');
    return parts.length > 1 ? parts.pop()?.toLowerCase() : '';
  };

  // Add function to handle file preview
  const handlePreviewFile = (file: typeof processedFiles[0], e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (!file) return;

    if (file.fileType && !audioPreview && audioURL.includes(file.fileType ?? "")) {
      setAudioPreview({
        path: file.S3Link || '',
        name: file.displayName
      });
    } else if (file.fileType && !showVideoPreview && videoURL.includes(file.fileType ?? "")) {
      setSelectedVideo({
        path: file.S3Link || '',
        name: file.displayName
      });
      setShowVideoPreview(true);
      setIsVideoLoading(true);
    } else if (!previewFile) {
      const previewFileWithType = {
        ...file,
        fileType: "document" as const // Explicitly set fileType to "document"
      };

      setPreviewFile(previewFileWithType);
    }
  };

  // Add function to close preview
  const closePreview = () => {
    setPreviewFile(null);
  };

  const handleVideoLoad = () => {
    setIsVideoLoading(false);
  };

  const closeVideoPreview = () => {
    setShowVideoPreview(false);
    setSelectedVideo(null);
  };

  const closeAudioPreview = () => {
    setAudioPreview(null);
  };

  // Process files only once when workSpaceFiles changes
  const processedFiles = useMemo(() => {
    if (!Array.isArray(workSpaceFiles) || workSpaceFiles.length === 0) return [];

    return workSpaceFiles.map((file: UploadFile) => {

      const isEmptyFile = file.file.name === "EMpty.txt";
      const fileType = isEmptyFile ? extractFileExtension(file?.S3Link ?? "") : file.file.type;
      const displayName = isEmptyFile ? processS3Filename(file?.S3Link) : getProcessedFileName(file);

      const isProcessing = file.status === "uploading";
      const isError = file.status === "error";
      const fileIcon = renderFileIcon(fileType ?? "", displayName, isProcessing, isError);
      const cleanedCustomName =  file && file.customName ? cleanupFileName(file.customName) : displayName;
      return {
        ...file,
        fileType,
        displayName,
        fileIcon,
        customName: cleanedCustomName,
      isProcessing,
        isError
      };
    });
  }, [workSpaceFiles]);

  if (!processedFiles.length) return null;

  const maxSources = isFreePlan ? 1 : 5;
  const usedSources = workSpaceFiles?.length || 0;

  return (
    <>
      {/* Render the FilePreviewModal when a file is selected */}
      {previewFile && (
        <FilePreviewModal
          file={adaptUploadFile(previewFile)}
          onClose={closePreview}
        />
      )}

      {showVideoPreview && selectedVideo && (
        <VideoPreview
          videos={[selectedVideo]}
          loading={isVideoLoading}
          handleVideoLoad={handleVideoLoad}
          onClose={closeVideoPreview}
        />
      )}

      {/* Audio preview modal */}
      {audioPreview && (
        <AudioPreview
          audio={audioPreview}
          onClose={closeAudioPreview}
        />
      )}

      {/* Wrap entire file list with PhotoProvider for image previews */}
      <PhotoProvider maskOpacity={0.91}>
        <div className={classNames(!hideContainer ? styles.container : styles.addSources, {
          [styles.light]: theme === "light",
          [styles.dark]: theme === "dark",
        })}>
          <div className={styles.headContainer}>
            <h5
              className={classNames(styles.heading, {
                [styles.light]: theme === "light",
                [styles.dark]: theme === "dark",
              })}
            >
              <FormattedMessage id="workspace.uploadedfiles.head" />
            </h5>
            {!hideSubhead && (
              <div
                className={classNames(styles.subheading, {
                  [styles.light]: theme === "light",
                  [styles.dark]: theme === "dark",
                })}
              >
                <FormattedMessage
                  id="workspace.uploadedfiles.subhead"
                  values={{ used: usedSources, max: maxSources }}
                />
              </div>
            )}
          </div>
          {processedFiles.map((file) => {
            const isImage = isImageFile(file.fileType);
            // Create the file item component
            const fileItem = (
              <div className={classNames(styles.filesContainer, {
                [styles.light]: theme === "light",
                [styles.dark]: theme === "dark",
              })}
                onClick={(e) => {
                  if (file.status === "uploaded" && !isImage) handlePreviewFile(file, e)
                }}
                style={{ cursor: file.status === "uploaded" ? 'pointer' : "" }} >
                <div className={styles.fileWrapper}>
                  <span className="flex-shrink-0"> {file.fileIcon} </span>
                  <div className={styles.fileNameWrapper}>
                    <div className={classNames(styles.fileName, {
                      [styles.light]: theme === "light",
                      [styles.dark]: theme === "dark",
                    })}>
                      {file.customName}
                    </div>
                    {(file.errorMessage && file.status === "error") ? (
                      <span className="text-[12px] font-normal leading-[15px] text-[#DC2626]">
                        {planName === "free" ? (
                          <FormattedMessage
                            id={file.errorMessage}
                            values={{ size: 10 }}
                          />
                        ) : planName === "standard" ? (
                          <FormattedMessage
                            id={file.errorMessage}
                            values={{ size: 50 }}
                          />
                        ) : (
                          <FormattedMessage id={file.errorMessage} />
                        )}
                      </span>
                    ) : file.status === "uploading" ? (
                      <div className={classNames(styles.uploadingContainer, {
                        [styles.light]: theme === "light",
                        [styles.dark]: theme === "dark",
                      })}>
                        <div className={styles.status}>
                          <FormattedMessage id="workspace.uploadedfiles.uploading" />
                        </div>
                        <div className={styles.percentage}>{file.progress}%</div>
                      </div>
                    ) : (
                      file.status === "uploaded" && (
                        <span className="text-[12px] font-inter font-normal leading-[15px] text-[#737373] dark: text-[rgba(255,255,255,0.7)">
                             {Number.isInteger(file.fileSize) ? file.fileSize : file.fileSize.toFixed(2)} {(file.fileSize === 0 || file.fileSize === 1 ) ? "token" : "tokens"}
                          {file.created_at === file.updated_at ? (
                            getRelativeTime(file.created_at ?? "")?.startsWith("-") ? "" : ` Added ${getRelativeTime(file.created_at ?? "")}`
                          ) : (
                            getRelativeTime(file.updated_at ?? "")?.startsWith("-") ? "" : ` Updated ${getRelativeTime(file.updated_at ?? "")}`
                          )}
                        </span>
                      )
                    )}
                  </div>
                  <div className={styles.icons}>
                    {file.status === "uploaded" ? (
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          if (file.S3Link) {
                            handleDeleteFile(
                              file.id,
                              file.S3Link,
                              file.uploaded_id ?? null
                            );
                          }
                        }}
                      >
                        <TrashIcon />
                      </span>
                    ) : file.status === "error" ? (
                      <span
                        onClick={(e) => handleRetry(e, { id: file.id, S3Link: file.S3Link ?? "" })}
                        style={{ cursor: 'pointer' }}
                      >
                        <ErrorRotateIcon />
                      </span>
                    ) : (
                      <span onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        handleRemoveWS(file.id)
                      }}>
                        <CrossIcon />
                      </span>
                    )}
                  </div>
                </div>
              </div>
            )

            if (isImage && file.status === "uploaded") {
              return (
                <PhotoView key={file.id} src={file.S3Link}>
                  {fileItem}
                </PhotoView>
              );
            }

            return (
              <React.Fragment key={file.id}>
                {fileItem}
              </React.Fragment>
            );

          })}
        </div>

      </PhotoProvider>
    </>
  );
};
