export interface IAction {
  readonly type: string;
}

export enum TYPES {
  // AUTHENTICATION
  SIGN_IN = "SIGN_IN",
  VERIFY_EMAIL = "VERIFY_EMAIL",
  CHANGE_EMAIL = "CHANGE_EMAIL",
  SOCIAL_CALLBACK = "SOCIAL_CALLBACK",
  LOGOUT = "LOGOUT",
  UPDATE_LANGUAGE = "UPDATE_LANGUAGE",
  INIT_USER_DETAILS = "INIT_USER_DETAILS",
  UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS",

  // CHAT MODELS
  GET_CHAT_MODELS = "GET_CHAT_MODELS",
  SET_CHAT_MODEL = "SET_CHAT_MODEL",
  SET_IMAGE_ATTRIBUTE = "SET_IMAGE_ATTRIBUTE",
  SET_SHOW_UPSCALE_MODAL = "SET_SHOW_UPSCALE_MODAL",
  SET_DROP_DOWN_OPTIONS = "SET_DROP_DOWN_OPTIONS",
  SET_SELECTED_OPTIONS = "SET_SELECTED_OPTIONS",
  UPDATE_DROP_DOWN_OPTIONS = "UPDATE_DROP_DOWN_OPTIONS",
  SET_REGENERATE_IMAGE_ATTRIBUTE = "SET_REGENERATE_IMAGE_ATTRIBUTE",

  // CHAT
  START_NEW_CHAT = "START_NEW_CHAT",
  NEW_CHAT = "NEW_CHAT",
  ANSWER_NEW_CHAT = "ANSWER_NEW_CHAT",
  REGULAR_CHAT = "REGULAR_CHAT",
  ANSWER_REGULAR_CHAT = "ANSWER_REGULAR_CHAT",
  GET_ALL_CHATS = "GET_ALL_CHATS",
  ADD_CHAT_HISTORY = "ADD_CHAT_HISTORY",
  UPDATE_CHAT_HISTORY = "UPDATE_CHAT_HISTORY",
  DELETE_CHAT_HISTORY = "DELETE_CHAT_HISTORY",
  GET_CHAT_HISTORY = "GET_CHAT_HISTORY",
  SAVE_FILE_PATH = "SAVE_FILE_PATH",
  GET_GENERATED_QUESTIONS_NEW = "GET_GENERATED_QUESTIONS_NEW",
  GET_GENERATED_QUESTIONS_HISTORY = "GET_GENERATED_QUESTIONS_HISTORY",
  REMOVE_RELATED_QUESTIONS_FROM_NEW_MESSAGES = "REMOVE_RELATED_QUESTIONS_FROM_NEW_MESSAGES",
  REMOVE_RELATED_QUESTIONS_FROM_MESSAGES = "REMOVE_RELATED_QUESTIONS_FROM_MESSAGES",
  REMOVE_LAST_MESSAGE_FROM_NEW_MESSAGES = "REMOVE_LAST_MESSAGE_FROM_NEW_MESSAGES",
  REMOVE_LAST_MESSAGE_FROM_MESSAGES = "REMOVE_LAST_MESSAGE_FROM_MESSAGES",
  GET_SHARE_CHAT = "GET_SHARE_CHAT",
  UPDATED_NEW_REGULAR_CHAT = "UPDATED_NEW_REGULAR_CHAT",
  UPDATE_REGULAR_CHAT = "UPDATE_REGULAR_CHAT",
  GET_CHAT_SETTING = "GET_CHAT_SETTING",
  DELETE_MULTIPLE_CHAT_HISTORY = "DELETE_MULTIPLE_CHAT_HISTORY",
  CLEAR_NEW_MESSAGES = "CLEAR_NEW_MESSAGES",
  GET_ROLL_BOT_SETTING = "GET_ROLL_BOT_SETTING",
  GET_ROLL_BOT = "GET_ROLL_BOT",
  SET_REMEMBER_Chat_Type = "SET_REMEMBER_Chat_Type",
  SET_CONTAINER_HEIGHT = "SET_CONTAINER_HEIGHT",
  SET_DRAG_FILE = "SET_DRAG_FILE",
  SAVE_ALL_CHATS = "SAVE_ALL_CHATS",
  DELETE_ALL_CHATS = "DELETE_ALL_CHATS",
  SET_PAGINATION = "SET_PAGINATION",
  SET_VIEW_CHAT_HISTORY = "SET_VIEW_CHAT_HISTORY",
  SET_SEARCH_WORD = "SET_SEARCH_WORD",
  SET_SEARCH_Query = "SET_SEARCH_Query",
  SET_Selected_MessageId = "SET_Selected_MessageId",
  SET_Scroll_Id = "SET_Scroll_Id",
  AUDIO_SRC = "AUDIO_SRC",
  //New
  GET_ALL_CHATS_HISTORY = "GET_ALL_CHATS_HISTORY",
  UPDATE_CHAT_MESSAGES = "UPDATE_CHAT_MESSAGES",
  DELETE_SELECTED_CHATS = "DELETE_SELECTED_CHATS",
  ADD_MESSAGE_CAT_HISTORY = "ADD_MESSAGE_CAT_HISTORY",
  ADD_MESSAGE = "ADD_MESSAGE",
  UPDATE_MESSAGE = "UPDATE_MESSAGE",
  UPDATE_ANSWER = "UPDATE_ANSWER",
  NEW_CHAT_QUESTION = "NEW_CHAT_QUESTION",
  REMOVE_CHAT_QUESTION = "REMOVE_CHAT_QUESTION",
  UPDATE_CHAT_QUESTION = "UPDATE_CHAT_QUESTION",
  ADD_MESSAGE_HISTORY = "ADD_MESSAGE_HISTORY",
  UPDATE_ASSISTANT_ANSWER = "UPDATE_ASSISTANT_ANSWER",
  SET_CHAT_HISTORY_LOADING = "SET_CHAT_HISTORY_LOADING",
  SET_PREVIEW_IMAGE = "SET_PREVIEW_IMAGE",
  PLAN_EXPIRE = "PLAN_EXPIRE",

  //MESSAGE
  MESSAGES_PAGINATION = "MESSAGES_PAGINATION",
  LOAD_MESSAGES = "LOAD_MESSAGES",
  UPDATE_MESSAGES_PAGINATION = "UPDATE_MESSAGES_PAGINATION",
  CLEAR_HISTORY_MESSAGES = "CLEAR_HISTORY_MESSAGES",
  SET_ADMIN_DELETED = "SET_ADMIN_DELETED",

  //REGENERATE MODEL NAME
  REGENERATE_MODEL_NAME = "REGENERATE_MODEL_NAME",

  // IMAGE GENERATION
  GENERATE_IMAGE_ERROR = "GENERATE_IMAGE_ERROR",
  UPSCALE_IMAGE = "UPSCALE_IMAGE",

  // PLAN SUBSCRIPTION
  GET_PLAN = "GET_PLAN",
  GET_ACTIVE_PLAN = "GET_ACTIVE_PLAN",
  GET_CREDITS_LIST = "GET_CREDITS_LIST",
  UPDATE_CREDITS = "UPDATE_CREDITS",
  GET_CREDIT_LIMITS = "GET_CREDIT_LIMITS",
  CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION",
  CREDITS_LOADING_ANIMATION = "CREDITS_LOADING_ANIMATION",

  // TEAM
  GET_ALL_TEAMS = "GET_ALL_TEAMS",
  SELECT_TEAM = "SELECT_TEAM",
  GET_TEAM_MEMBERS = "GET_TEAM_MEMBERS",
  GET_TEAM_INFO = "GET_TEAM_INFO",
  INVITE_TEAM_MEMBER = "INVITE_TEAM_MEMBER",
  REMOVE_TEAM_MEMBER = "REMOVE_TEAM_MEMBER",
  UPDATE_MEMBER_ROLE = "UPDATE_MEMBER_ROLE",
  UPDATE_MEMBER_DAILY_LIMIT = "UPDATE_MEMBER_DAILY_LIMIT",
  RESEND_INVITE = "RESEND_INVITE",
  CANCEL_INVITE = "CANCEL_INVITE",
  UPDATE_MEMBER_NAME = "UPDATE_MEMBER_NAME",

  //ACCOUNT
  ACCOUNT_SWITCH = "ACCOUNT_SWITCH",

  // SYSTEM
  SET_SYSTEM_THEME = "SET_SYSTEM_THEME",
  GET_SYSTEM_THEME = "GET_SYSTEM_THEME",
  SET_PRIVATE_CHAT = "SET_PRIVATE_CHAT",
  SET_REMEMBER_SETTING = "SET_REMEMBER_SETTING",

  // PROPMT LIBRARY
  GET_ALL_PROMPT = "GET_ALL_PROMPT",
  GET_USER_PROMPT = "GET_USER_PROMPT",
  DELETE_USER_PROMPT = "DELETE_USER_PROMPT",
  UPDATE_USER_PROMPT = "UPDATE_USER_PROMPT",

  //CHAT FOLDER
  SHOW_FOLDERS = "SHOW_FOLDERS",
  DEL_FOLDER = "DEL_FOLDER",
  UPDATE_FOLDER = "UPDATE_FOLDER",
  ADD_FOLDER = "ADD_FOLDER",
  FOLDER_NAME = "FOLDER_NAME",
  SET_ACCORDION_STATE = "SET_ACCORDION_STATE",
  ADD_NEW_CHAT = "ADD_NEW_CHAT",
  UPDATE_CHAT_TOPIC = "UPDATE_CHAT_TOPIC",
  DELETE_CHAT_FOLDER = "DELETE_CHAT_FOLDER",
  DELETE_SELECTED_CHAT_FOLDER = "DELETE_SELECTED_CHAT_FOLDER",
  DELETE_MULTIPLE_CHAT_HISTORY_FOLDER = "DELETE_MULTIPLE_CHAT_HISTORY_FOLDER",
  APPEND_CHAT_FOLDER = "APPEND_CHAT_FOLDER",
  GET_FOLDER_CHAT = "GET_FOLDER_CHAT",
  EMPTY_FOLDER = "EMPTY_FOLDER",
  DEL_FOLDER_CHATS = "DEL_FOLDER_CHATS",
  EMPTY_DEFAULT_FOLDER_CHATS = "EMPTY_DEFAULT_FOLDER_CHATS",
  SET_CURRENT_INDEX="SET_CURRENT_INDEX",

  //WORKSPACE
  CREATE_WORKSPACE = "CREATE_WORKSPACE",
  ADD_FILE = "ADD_FILE",
  UPDATE_FILE_STATUS = "UPDATE_FILE_STATUS",
  REMOVE_FILE = "REMOVE_FILE",
  DISPLAY_FILE = "DISPLAY_FILE",
  UPDATE_FILE_S3_LINK = "UPDATE_FILE_S3_LINK",
  UPDATE_FILE_ERR_MESSAGE="UPDATE_FILE_ERR_MESSAGE",
  UPDATE_FILE_PROGRESS="UPDATE_FILE_PROGRESS",
  UPDATE_FILE_SIZE="UPDATE_FILE_SIZE",
  ADD_WORKSPACE="ADD_WORKSPACE",
  WS_UPLOADED_FILES="WS_UPLOADED_FILES",
  UPDATE_UPLOADED_FILE="UPDATE_UPLOADED_FILE",
  REMOVE_WS_FILES="REMOVE_WS_FILES",
  WS_UPLOADED_FILES_REMOVE="WS_UPLOADED_FILES_REMOVE",
  ADD_FILE_UPLOADED="ADD_FILE_UPLOADED",
  DEL_REMOVE_FILE="DEL_REMOVE_FILE",
  EMPTY_FILE="EMPTY_FILE",
  UPLOADED_FILE_ID="UPLOADED_FILE_ID",
  WS_CREATED="WS_CREATED",
  WS_Updated="WS_Updated",
  WS_PROGESSVALUES="WS_PROGESSVALUES",
  CURRENT_CONVERSATION="CURRENT_CONVERSATION",
  WS_NAMEUPDATED="WS_NAMEUPDATED",
  EMPTY_WS_UPLOADED_FILES="EMPTY_WS_UPLOADED_FILES",
  WORKSPACE_UPLOADED_FILES="WORKSPACE_UPLOADED_FILES",
  UPDATE_FILE_NAME = "UPDATE_FILE_NAME",

  //WorkSpace Thread Section
  CHAT_CONTENT_UPLOADING_FILES="CHAT_CONTENT_UPLOADING_FILES",
  CHAT_CONTENT_UPDATE_FILE_STATUS="CHAT_CONTENT_UPDATE_FILE_STATUS",
  FILE_PREVIEW_UPLOADING_FILES="FILE_PREVIEW_UPLOADING_FILES",
  DEL_PREVIEW_UPLOADING_FILES="DEL_PREVIEW_UPLOADING_FILES",
  EMPTY_PREVIEW_UPLOADING_FILES="EMPTY_PREVIEW_UPLOADING_FILES",
  SAVE_THREADS_HISTORY= "SAVE_THREADS_HISTORY",
  DEL_CHAT_UPLOAD_FILE="DEL_CHAT_UPLOAD_FILE",
  EMPTY_THREADS_HISTORY="EMPTY_THREADS_HISTORY",
  DEL_CHAT_UPLOAD_FILES="DEL_CHAT_UPLOAD_FILES",
  MESSAGE_GENERATION="MESSAGE_GENERATION",
  IS_GENERATE_RES="IS_GENERATE_RES",
  SET_CONTROLLER="SET_CONTROLLER",
  CLEAR_CONTROLLER="CLEAR_CONTROLLER",

  DELETE_THREADS="DELETE_THREADS",
  TH_TITLE_Updated="TH_TITLE_Updated",
  THREAD_CHAT_HISTORY="THREAD_CHAT_HISTORY",
  GET_THREAD_HISTORY="GET_THREAD_HISTORY",
  GET_THREAD_TOPIC_INFO="GET_THREAD_TOPIC_INFO",
  NEW_CHAT_THREAD_WS="NEW_CHAT_THREAD_WS",
  EMPTY_NEW_CHAT_THREAD="EMPTY_NEW_CHAT_THREAD",
  CHAT_CONTENT_NEW_UPLOADING_FILES="CHAT_CONTENT_NEW_UPLOADING_FILES",
  CHAT_CONTENT_UPDATE_NEW_FILE_STATUS="CHAT_CONTENT_UPDATE_NEW_FILE_STATUS",
  SET_NEW_CHAT_MODAL="SET_NEW_CHAT_MODAL",
  DELETE_CHAT_CONTENT_BY_FILE="DELETE_CHAT_CONTENT_BY_FILE",
  SET_SELECTED_SUB_OPTIONS= "SET_SELECTED_SUB_OPTIONS", 
}
