// FilePreviewModal/components/FilePreviewHeader.tsx
import React from "react";
import { FilePreviewCrossIcon } from "../../icons/CrossIcon";
import styles from "../FilePreviewModal.module.scss";
import classNames from "classnames";
import { EThemeType } from "redux/reducers";

interface FilePreviewHeaderProps {
  fileName: string;
  fileSize?: number;
  lineCount: number | null;
  onClose: () => void;
  theme: EThemeType | undefined;
}

const FilePreviewHeader: React.FC<FilePreviewHeaderProps> = ({ 
  fileName, 
  fileSize, 
  lineCount, 
  onClose,
  theme
}) => {
  return (
    <div className={styles.modalHeader}>
      <div className="flex justify-between">
        <h2 className={`font-inter ${styles.fileName}`}>{fileName}</h2>
        <span onClick={onClose}>
        <button className={styles.closeButton} >
          <FilePreviewCrossIcon />
        </button>
        </span>
      </div>

      <div className={classNames("font-inter", styles.fileMetadata, {
        [styles.dark]: theme === "dark"
      })}>
        {fileSize && <span>{Number.isInteger(fileSize) ? fileSize : fileSize.toFixed(2)} {(fileSize === 0 || fileSize === 1 ) ? "token" : "tokens"}</span>}
        {fileSize && lineCount && <span className={styles.metadataDot}>•</span>}
        {lineCount !== null && <span>{lineCount} lines</span>}
        <span className={styles.metadataDot}>•</span>
        <span>Formatting may be inconsistent from source</span>
      </div>
    </div>
  );
};

export default FilePreviewHeader;