import classNames from "classnames";
import styles from "../workSpaceConversation.module.scss";
import TextareaAutosize, {
  TextareaAutosizeProps,
} from "react-textarea-autosize";
import { useSelector } from "redux/hooks";
import { Prompt } from "pages/Workspaces/components/icons/Prompt";
import { AIBehaviour } from "pages/Workspaces/components/icons/AIBehaviour";
import { LinkIcon } from "pages/Workspaces/components/icons/LinkIcon";
import { UploadFilesIcon } from "pages/Workspaces/components/icons/UploadFilesIcon";
import {
  SendIcon,
  StopGeneratingIcon,
} from "pages/Workspaces/components/icons/SendIcon";
import AnimatedCircle from "components/AnimatedCircle/AnimatedCircle";
import { useIntl } from "react-intl";
import { useSidebar } from "hooks/services/ReSizeSidebar";
import { useWindowSize } from "hooks/useWindowSize";
import {
  Dispatch,
  SetStateAction,
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
} from "react";
import { useEffectOnce } from "react-use";
import AnimatedModal from "components/AnimateModal/AnimateModal";

import {
  getSavePrompts,
  getAllPropts,
  EmptyUploadedFiles,
  setEmptyPreview,
  setMsgGeneration,
  clearhistoryMessages,
  startNewChat,
  selectedThread,
  SaveSelectedThread,
  setIsModalOpen,
  IChatContentUploadFile,
  setWsUploadedFile,
  RoleBot,
  setSelectedSubOptions,
} from "redux/actions";
import useRouter from "hooks/useRouter";
import { PromptLibrary } from "pages/ChatPage/components/chatFooter/components/PromptLibrary";
import { GPTModal } from "views/layout/Navbar/components/GPTModal";
import BotSetting from "pages/ChatPage/components/chatFooter/components/BotSetting/BotSetting";
import { RoutePaths } from "pages/routePaths";
import { ContextDropdown } from "./ContextDropdoen";
import { WorkspaceCredits } from "./WorkspaceCredits";
import { useFileUpload } from "hooks/useFileUpload";
import { AllowedFileTypes } from "utils/constants";
import { AddSource } from "./AddSource";
import { calculateCredit } from "utils/functions";
import { CreditLimitModal } from "pages/ChatPage/components/creditLimitModal";
import { useThreadsHistoryContext } from "hooks/ThreadHistoryContext";
import { ErrorHandler } from "pages/Workspaces/components/ErrorHandler/ErrorHandler";
import { useToggleSidebar } from "hooks/services/ToggleSidebarProvider";
import { CrossIcon } from "pages/Workspaces/components/icons/CrossIcon";

import { RIPPLE_COLORS } from "utils/constants";
import { RippleIconButton } from "components/RippleEffect/RippleEffects";
interface IProps extends TextareaAutosizeProps {
  threads?: boolean;
  openHistory?: boolean;
  isAnswerComplete?: boolean;
  isFileUploading?: boolean;
  onEnter?: ({
    event,
    message,
  }: {
    event: React.KeyboardEvent<HTMLTextAreaElement>;
    message: string;
  }) => void;
  addSource?: boolean;
  uploadeURL?: boolean;
  setUploadURL?: Dispatch<SetStateAction<boolean>>;
  conversation?: boolean;
  chat?: boolean;
  wsNewChat?: boolean;
  isAllowUploadFile?: boolean;
  chatPage?: boolean;
}
export const WorkspaceSearchField = ({
  threads,
  isFileUploading,
  onEnter,
  addSource,
  uploadeURL,
  setUploadURL,
  conversation,
  chat,
  wsNewChat,
  isAllowUploadFile,
  chatPage,
  ...props
}: IProps) => {
  const {
    loadingSetting,
    settings,
    credit,
    setCredit,
    chatFooterRef,
    creditLimitModal,
    toggleCreditLimitModal,
    onConfirmCreditModal,
    onSendMessage,
    sendMessageLoading,
    onStopGeneratingResponse,
    isGenerating,
    onCustomSubmit,
    getMessagesLoading,
    openHistory,
    isAnswerComplete,
  } = useThreadsHistoryContext();

  const { chatContentUploadingFiles, msgGeneration, isGeneRep, newChatThread, isModalOpen, currentConversation, selectedThread } = useSelector(
    (state) => state.workSpaceReducer
  );

  const {
    handleFileSelect,
    errorModal,
    setErrorModal,
    messageId,
    setMessageId,
    handleFileUpload,
  } = useFileUpload(false);

  const { isOpen } = useToggleSidebar();
  const { width } = useWindowSize();
  const { sidebarWidth } = useSidebar();
  const { theme } = useSelector((state) => state.authReducer);
  const { push, pathname } = useRouter();

  const { formatMessage } = useIntl();
  const [isPromptClicked, setIsPromptClicked] = useState(false);
  const [promptLoading, setPromptLoading] = useState<boolean>(false);
  const [userPromptLoading, setUserPromptLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [isGPTModalOpen, setIsGPTModalOpen] = useState(false);
  const [isBotSetting, setIsBotSetting] = useState(false);
  const { communityPrompts, newMessages, messages, selectedSubOptions } = useSelector((state) => state.chatReducer);
  const chatModel = useSelector((state) => state.authReducer.gptModel);
  const { gptModel } = useSelector((state) => state.authReducer);
  const storedGptModel = localStorage.getItem("GptModel");
  const parsedGptModel =
    storedGptModel &&
      storedGptModel !== undefined &&
      storedGptModel !== "undefined"
      ? JSON.parse(storedGptModel)
      : null;
  const { chatModels } = useSelector((state) => state.chatModelsReducer);
  const { workSpaces } = useSelector((state) => state.workSpaceReducer);
  const workspaceId = pathname.split("/")[2];
  const acceptString = AllowedFileTypes.map((val) => ` .${val}`).toString();
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const [url, setURL] = useState<string>("");
  const newChat = pathname.includes("thread");

  const pathParts = pathname.split("/").filter(Boolean);

  // Parse path to determine which component to render
  const isWorkspacePath = pathParts[0] === "workspaces";
  const hasThreadId = pathParts.includes("thread") && pathParts.length > pathParts.indexOf("thread") + 1;
  const renderThreadsContent = isWorkspacePath && hasThreadId;

  const [uploadNewURL, setUploadNewURL] = useState<boolean>(false);
  const [isReadOnly, setIsReadOnly] = useState(false);

  const resetMessage = () => {
    setMessage("");
  };

  const onTextareaFocus = () => {
    if (textareaRef.current) textareaRef.current.focus();
  };

  useImperativeHandle(chatFooterRef, () => ({
    resetMessage,
    onTextareaFocus,
  }));

  useEffect(() => {
    if (wsNewChat !== true) {
      setIsReadOnly(getMessagesLoading || (width <= 768 && isOpen));
    } else setIsReadOnly(false);
  }, [getMessagesLoading, width, isOpen, wsNewChat]);

  useEffect(() => {
    if (!gptModel) return;
    if (isAnswerComplete === true) {
      const credit = calculateCredit(
        gptModel,
        settings,
        chatContentUploadingFiles,
        url,
        isAnswerComplete
      );
      setCredit(credit);
    }
  }, [
    chatContentUploadingFiles,
    settings,
    gptModel,
    url,
    isAnswerComplete,
    setCredit,
  ]);

  const handleBotSetting = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsBotSetting(true);
  };

  const handlePromptClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setIsPromptClicked(!isPromptClicked); // Toggle the state on click
  };

  useEffectOnce(() => {
    if (communityPrompts.length === 0) {
      setPromptLoading(true);
      setUserPromptLoading(true);

      Promise.all([
        getSavePrompts().then(
          () => setUserPromptLoading(false),
          () => setUserPromptLoading(false)
        ),
        getAllPropts().then(
          () => setPromptLoading(false),
          () => setPromptLoading(false)
        ),
      ]).finally(() => {
        setPromptLoading(false);
        setUserPromptLoading(false);
      });
    }
  });
  const getMessage = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setMessage(e.target.value);

  const isFileUpload: string[] = (chatContentUploadingFiles ?? [])
    .filter(
      (file: IChatContentUploadFile) => file.status === "validating" || file.status === "uploading"
    )
    .map((file: IChatContentUploadFile) => file.status);

  const disableBtn = isFileUpload.length > 0 ||
    (chatContentUploadingFiles.length === 0 && message === "" &&
      !sendMessageLoading && !msgGeneration);

  const resetWsNewChatState = () => {
    startNewChat();
    clearhistoryMessages();
    setIsModalOpen(false);
    const SaveThreadInfo: selectedThread = {
      ws_name: "",
      emoji: "",
      thread_name: "",
      thread_id: 0,
      ws_id: 0,
    }
    SaveSelectedThread(SaveThreadInfo);
    if (currentConversation && currentConversation.id !== selectedThread.thread_id) {
      setWsUploadedFile();
    }
  }

  const handleMessageSubmission = (
    event: React.FormEvent | React.MouseEvent
  ) => {
    if (disableBtn) return;

    if (wsNewChat) {
      resetWsNewChatState();
    }

    if (
      chatContentUploadingFiles &&
      chatContentUploadingFiles.length > 0 &&
      openHistory === false &&
      isAnswerComplete === true &&
      isFileUpload.length === 0
    ) {
      const s3Links: string[] = (chatContentUploadingFiles ?? [])
        .map((file: IChatContentUploadFile) => file.S3Link)
        .filter((link): link is string => link !== undefined);

      if (
        chatContentUploadingFiles &&
        chatContentUploadingFiles[0]?.fileType === "image"
      ) {
        if (wsNewChat) {
          onSendMessage!(message ? message : "", chatModel, false, s3Links ?? [], [], newChatThread.id !== 0 ? newChatThread.id.toString() : workspaceId);
          setTimeout(() => {
            push(`/${RoutePaths.Workspaces}/${newChatThread.id !== 0 ? newChatThread.id : workspaceId}/thread`);
          }, 5)
        } else {
          onSendMessage!(message ? message : "", chatModel, false, s3Links ?? [])
        }

      } else if (
        chatContentUploadingFiles &&
        (chatContentUploadingFiles[0]?.fileType === "document" ||
          chatContentUploadingFiles[0]?.fileType === "video" ||
          chatContentUploadingFiles[0]?.fileType === "audio")
      ) {

        setMsgGeneration(true);
        if (wsNewChat) {
          onSendMessage(
            message ? message : "",
            chatModel,
            false,
            [],
            s3Links ?? [],
            newChatThread.id !== 0 ? newChatThread.id.toString() : workspaceId  // ws_id as the 6th parameter
          );
          setTimeout(() => {
            push(`/${RoutePaths.Workspaces}/${newChatThread.id !== 0 ? newChatThread.id : workspaceId}/thread`);
          }, 5);
        }
        else {
          onSendMessage(
            message ? message : "",
            chatModel,
            false,
            [],
            s3Links ?? [],
          );
        }
      }

      EmptyUploadedFiles();
      if (!newChat) {
        setTimeout(() => {
          push(`/${RoutePaths.Workspaces}/${workspaceId}/thread`);
        }, 0);
      }
    } else if (
      isFileUpload.length === 0 &&
      chatContentUploadingFiles &&
      chatContentUploadingFiles.length === 0
    ) {

      if (wsNewChat) {
        onCustomSubmit?.({ event: event, message, ...(wsNewChat && { ws_id: newChatThread.id !== 0 ? newChatThread.id.toString() : workspaceId }) });
        setTimeout(() => {
          push(`/${RoutePaths.Workspaces}/${newChatThread.id !== 0 ? newChatThread.id : workspaceId}/thread`);
        }, 5);
      }
      else {
        onCustomSubmit?.({ event: event, message });
      }
      setMsgGeneration(true);
      if (!newChat) {
        setTimeout(() => {
          push(`/${RoutePaths.Workspaces}/${workspaceId}/thread`);
        }, 0);
      }
    }
  };

  // Then update the form submission handler
  const handleFormSubmit = (event: React.FormEvent) => {
    handleMessageSubmission(event);
  };

  // And the button click handler
  const handleSubmit = (event: React.MouseEvent) => {
    handleMessageSubmission(event);
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (!settings?.send_message_with_enter) {
      if ((event.metaKey || event.ctrlKey) && event.key === "Enter") {
        event.preventDefault();
        handleMessageSubmission(event);
      }
    } else {
      if (!event.shiftKey && event.key === "Enter") {
        event.preventDefault();
        handleMessageSubmission(event);
      }
    }
  };

  const handlePaste = async (
    event: React.ClipboardEvent<HTMLTextAreaElement>
  ) => {
    const items = event.clipboardData.items;
    let files: File[] = [];
    const TEXT_SIZE_THRESHOLD = 8192;
    const MAX_FILES = 25;

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.kind === "file") {
        const file = item.getAsFile();
        if (file) {
          if (
            // (history ) ||
            newMessages[0]?.chatType === "text" ||
            messages[0]?.chatType === "text"
          ) {
          } else if (
            newMessages.length === 0 ||
            (newMessages[0]?.files && newMessages[0]?.files.length > 0) ||
            (newMessages[0]?.images && newMessages[0]?.images.length > 0)
          )
            files.push(file);
          if (files.length > MAX_FILES) {
            files.shift();
          }
        }
      }
    }

    const pastedText = event.clipboardData.getData("text");

    if (pastedText.length > TEXT_SIZE_THRESHOLD) {
      event.preventDefault();

      if (files.length > MAX_FILES) {
        files = files.slice(1); // Remove oldest file
      }

      const blob = new Blob([pastedText], { type: "text/plain" });
      const textFile = new File([blob], `pasted-content-${Date.now()}.txt`, { type: "text/plain" });

      files.push(textFile);
    }
    if (files.length > 0) {
      // Convert array of Files to FileList-like object
      const dataTransfer = new DataTransfer();

      files.forEach(file => {
        dataTransfer.items.add(file);
      });

      // Now you can get a FileList from dataTransfer.files
      handleFileUpload(dataTransfer.files, isModalOpen);
    }
  };

  const handleUploadURL = () => {
    setUploadURL?.(true);
    if (wsNewChat) setUploadNewURL(true);
  };

  const handleClose = () => {
    // EmptyUploadedFiles();
    setUploadURL?.(false);
    setEmptyPreview();
    if (wsNewChat) setUploadNewURL(false);
  };

  const validParsedGptModel =
    parsedGptModel &&
      chatModels?.some(
        (model) =>
          model.id === parsedGptModel.id && model.name === parsedGptModel.name
      )
      ? parsedGptModel
      : null;

  const modelToUse =
    (gptModel?.type?.includes("text") &&
      !renderThreadsContent &&
      validParsedGptModel) ||
    gptModel;

  const focusTextArea = () => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  };

  const handleUploadURLClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (isAllowUploadFile !== false || wsNewChat) handleUploadURL()
  }

  const handleFileUploadClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (isAllowUploadFile !== false || wsNewChat)
      document.getElementById("fileUploadInput")?.click()
    }

    const disableSendIcon = ( !isGenerating && !isGeneRep && (sendMessageLoading || msgGeneration )) || disableBtn;

    const themeColors = theme === 'dark' ? RIPPLE_COLORS.dark : RIPPLE_COLORS.light;

  const isDefault = Object.values(selectedSubOptions).every(value => value === "default");

  return (
    <>
      <form
        className={`w-[100%] ${(getMessagesLoading || workSpaces.length === 0)? styles.isbackdrop : ""}`}
        onSubmit={handleFormSubmit}
        data-testid="chat-footer"
      >
        <div
          className={classNames(styles.textAreaContainer, {
            [styles.light]: theme === "light",
            [styles.dark]: theme === "dark",
            [styles.activeborder]: true,
            [styles.threads]: threads,
            [styles.chatPage]: chatPage,
            [styles.textContainer]:
              (sidebarWidth > 287 && width <= 1150) ||
              (sidebarWidth > 497 && width <= 1323) ||
              (sidebarWidth > 480 && width <= 1305),
          })}
          onClick={focusTextArea}
        >
          <div className={`${width < 576 ? "right-[12px]" : "right-0"} relative -top-5 left-3 flex justify-between z-10 font-inter`}>
            <ContextDropdown wsNewChat={wsNewChat} />
            <WorkspaceCredits credit={credit} />
          </div>

          <div className={`flex w-[100%] font-inter`}>
            <TextareaAutosize
              ref={textareaRef}
              className={classNames("font-inter", styles.textarea, {
                [styles.light]: theme === "light",
                [styles.dark]: theme === "dark",
              })}
              minRows={3}
              maxRows={25}
              placeholder=""
              value={message}
              onChange={getMessage}
              onKeyDown={handleKeyDown}
              onPaste={handlePaste}
              readOnly={isReadOnly}
              {...props}
            />
          </div>
          <div className="flex w-full justify-between font-inter" onClick={(e) => { e.stopPropagation(); focusTextArea(); }}>
            <div>
              <div className={styles.mainContainer}>
                <div onClick={handlePromptClick} className="cursor-pointer">
                  <AnimatedCircle
                    name={formatMessage({ id: "prompt.library" })}
                  >
                    <span className="flex-shrink-0">
                      <Prompt />
                    </span>
                  </AnimatedCircle>
                </div>
                <div
                  onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                    e.stopPropagation();
                    setIsGPTModalOpen(true);
                  }}
                >
                  <AnimatedCircle name={modelToUse?.name ?? ""}>
                    <div >
                      <img
                        data-testid="model-image"
                        className={classNames(
                          (modelToUse?.image_url ?? "").includes("flux.png")
                            ? "w-[22px] max-h-[22px] ${styles.imagebackground}"
                            : "w-[22px] max-h-[22px]",
                          "object-contain"
                        )}
                        src={
                          modelToUse?.image_url ??
                          "https://api-staging.deftgpt.com/images/ai-models/gpt.svg"
                        }
                        alt={modelToUse?.name ?? "Chat Model"}
                      />
                    </div>
                  </AnimatedCircle>
                </div>
                <div onClick={handleBotSetting}>
                  <AnimatedCircle
                    name={
                      <>
                        <div className="flex items-center gap-2">
                          {formatMessage({ id: isDefault ? "default.response" : "customized.response" })}
                          {!isDefault && (
                            <span className="mt-[4px]"
                              onClick={(e) => {
                                e.stopPropagation();
                                // Reset all selectedSubOptions to default
                                const updatedBot = {
                                  key: "bot_settings",
                                };
                                setSelectedSubOptions({
                                  outputFormats: 'default',
                                  tones: 'default',
                                  writingStyles: 'default',
                                  responseLengths: 'default',
                                  language: 'default',
                                });
                                RoleBot(updatedBot);
                              }}
                            >
                            <CrossIcon />
                            </span>
                          )}
                        </div>
                      </>
                    }
                    botSettings={!isDefault}
                  >
                    <span className="flex-shrink-0">
                      <AIBehaviour botSettings={!isDefault} />
                    </span>
                  </AnimatedCircle>
                </div>
              </div>
              {isBotSetting && (
                <BotSetting
                  onClose={() => setIsBotSetting(false)}
                  loadingSetting={loadingSetting}
                  conversation={conversation}
                  chat={chat}
                  chatPage={chatPage}
                />
              )}
            </div>
            <div className={styles.mainContainer}>
              <RippleIconButton
              disabled={isAllowUploadFile === false && !wsNewChat }
              color={themeColors.default}
                className={classNames(isAllowUploadFile === false && !wsNewChat 
                  ? "cursor-not-allowed opacity-[60%]"
                  : "cursor-pointer ", styles.rightContainerbox, {
                  [styles.light]: theme === "light",
                  [styles.dark]: theme === "dark",
                })}
                onClick={handleFileUploadClick}
              >
                <UploadFilesIcon />
                {(!addSource && !uploadeURL) && (
                  <input
                    id="fileUploadInput"
                    type="file"
                    multiple
                    accept={acceptString}
                    onChange={(e) => handleFileSelect(e, isModalOpen)}
                    style={{ display: "none" }}
                  />
                )}
              </RippleIconButton>
              <RippleIconButton
              disabled={isAllowUploadFile === false && !wsNewChat }
              color={themeColors.default}
                className={classNames(isAllowUploadFile === false && !wsNewChat
                  ? "cursor-not-allowed opacity-[60%]"
                  : "cursor-pointer ", styles.rightContainerbox, {
                  [styles.light]: theme === "light",
                  [styles.dark]: theme === "dark",
                })}
                onClick={handleUploadURLClick}
              >
                <LinkIcon />
              </RippleIconButton>
              <RippleIconButton
              disabled={disableSendIcon}
              color={themeColors.default}
                className={`${disableSendIcon ? "cursor-not-allowed"  : "cursor-pointer "}
                ${styles.rightContainerbox} ${styles.sendContainer}`}
                onClick={(event) => {
                  event.stopPropagation();
                  if (
                    (sendMessageLoading || msgGeneration) &&
                    (isGenerating || isGeneRep)
                  ) {
                    onStopGeneratingResponse();
                  } else handleSubmit(event);
                }}
              >
                {sendMessageLoading || msgGeneration ? (
                  <StopGeneratingIcon />
                ) : (
                  <SendIcon />
                )}
              </RippleIconButton>
            </div>
          </div>
        </div>
      </form>
      {isPromptClicked && (
        <PromptLibrary
          onClose={() => setIsPromptClicked(false)}
          setMessage={setMessage}
          promptLoading={promptLoading}
          userPromptLoading={userPromptLoading}
          textareaRef={textareaRef}
        />
      )}
      {isGPTModalOpen && <GPTModal onClose={() => setIsGPTModalOpen(false)} />}
      <AnimatedModal isOpen={wsNewChat ? uploadNewURL : uploadeURL ?? false} onClose={handleClose}>
        <AddSource
          onClose={handleClose}
          chatContent={true}
          setURL={setURL}
          message={message}
          onSendMessage={onSendMessage}
          defaultOption="urls"
        />
      </AnimatedModal>
      {creditLimitModal && (
        <CreditLimitModal
          onCancel={toggleCreditLimitModal}
          onClose={toggleCreditLimitModal}
          onConfirm={onConfirmCreditModal}
        />
      )}
      <ErrorHandler
        errorModal={errorModal}
        setErrorModal={setErrorModal}
        messageId={messageId}
        onConfirm={() =>
          push(`/${RoutePaths.Settings}/${RoutePaths.CurrentPlan}`)
        }
        onCancel={() => setMessageId("")}
      />
    </>
  );
};
